import React, {useContext, useMemo} from 'react';
import './bid-and-followers-block.css';
import {LotsContext} from "../../auction-lots-listing/auction-lots-listing";
import {includes, filter} from "lodash/collection";
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";


const BidAndFollowersBlock = () => {

    const {phrases: {ak_au_lot_watchs, ak_au_bids}, type, user_lots_type} = useContext(LotsContext);
    const {lotData:{count_lot_watch, bids}} = useContext(lotContext);

    const arrObj = useMemo(() => {

        const arr = [
            {phrase: ak_au_bids, value: bids, condition: true},
            {phrase: ak_au_lot_watchs, value: count_lot_watch, condition: user_lots_type !== 'archive_lots' || !includes(['no_sale', 'all_user_bets'], type)}
        ];

        return filter(arr, (a) => {
            return a.condition
        })
    }, [bids, count_lot_watch, user_lots_type, type])

    return arrObj.map((item, key) => (
        <div key={key} className="my-1 d-flex">
            <span className="">{item.phrase}:</span>
            <span className="ml-xl-auto mx-1">{item.value}</span>
        </div>
    ))
};

export default BidAndFollowersBlock