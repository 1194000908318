import React, {useMemo} from 'react'
import ButtonSortingSettings from "./button-sorting-settings";
import ButtonCategorySettings from "./button-category-settings";
import SortButtons from "./sort-buttons";
import GlobalHelper from "../../../helpers/global-helper";
import LotsTypeButtons from "./lots-type-buttons";
import {getQueryLinkParams} from "../../../helpers/routing-helper";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

const ShopSortMenu = () => {

    const location = useLocation();
    const { search: urlSearch, pathname } = location;

    const {user_sort_data, lots:lotsState} = useSelector(state => {
        const {lots} = state;
        return {
            user_sort_data: lots.user_sort_data,
            lots,
        };
    });

    const query = useMemo(() => getQueryLinkParams(user_sort_data), [user_sort_data, urlSearch, pathname]);
    const sortingData = useMemo(() => GlobalHelper.getSortingData({...lotsState, ...query}), [lotsState, query]);

    return (
        <div className="col-lg-12">
            <div className="shop-top-bar flex-wrap">
                <div className="d-flex align-items-center flex-wrap">
                    <LotsTypeButtons/>
                    <SortButtons {...query} sortingData={sortingData}/>
                    <ButtonSortingSettings {...query} {...lotsState} />
                    <ButtonCategorySettings {...query} {...lotsState} />
                </div>
            </div>
        </div>
    );
};

export default ShopSortMenu;
