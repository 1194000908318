import React from 'react';
import Spinner from "../spinner";

const StartSpinerBlock = () => {
    return (
        <div className="container">
            <div className="row flex-column justify-content-center">
                <Spinner/>
            </div>
        </div>
    );
}
export default StartSpinerBlock;