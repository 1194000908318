import React, {createContext, useMemo} from "react"
import './auction-lots-listing.css';
import MainLotView from "./components/main-lots-view";

import useLotListFn from "./useLotListFn";
import LotsListItem from "./components/lots-list-item/lots-list-item";

const LotsContext = createContext();
export {LotsContext};
const AuctionLotsListing = () => {
    const lotListData = useLotListFn();
    const {lotsData, phrases: {you_have_no_auctions, no_lots_sold_or_hidden}, type} = lotListData

    const lotList = useMemo(() => {
        return lotsData.map((lotData) => <LotsListItem
            key={type === 'all_user_bets' ? lotData.bid_id : lotData.auction_id}
            lotData={lotData}
        />)
    }, [lotsData]);

    return (
        <MainLotView>
            <LotsContext.Provider value={{
                ...lotListData
            }}>
                {lotsData.length === 0
                    ? <div key={'no_lots'}>{type === 'sale' ? no_lots_sold_or_hidden : you_have_no_auctions}</div>
                    : lotList
                }
            </LotsContext.Provider>
        </MainLotView>
    );
};

export default AuctionLotsListing;
