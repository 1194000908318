import {SET_PREFIX_MODAL} from "../../types";

const initialState = {
    modalShow: false,
}

export const modalPrefixReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PREFIX_MODAL:
            return {...state, ...action.payload};
        default:
            return state
    }
}