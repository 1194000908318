import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getLinkData} from "../../redux/lots/action";
import {getArchiveSwichData} from "../../redux/archive/actionArchive";
import {find} from "lodash/collection";
import GlobalHelper from "../../helpers/global-helper";
import {getQueryLinkParams} from "../../helpers/routing-helper";
import {useNavigate} from "react-router-dom";

const YearsNavPanel = () => {
    const {years, all_categories} = useSelector((state) => state.lots);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {type, user_lots_type, year: year_change, month: month_change, auction_category_id} = getQueryLinkParams();

    const getLink = useCallback((year) => {
        const {link} = find(dispatch(getArchiveSwichData(year)), {type});
        if (auction_category_id !== 0) {
            const category = find(all_categories, {auction_category_id});
            return `${link}${GlobalHelper.getCategoryVariableReference(category)}`;
        }
        return link;
    }, [dispatch, type, all_categories, auction_category_id]);

    const createButton = useMemo(() => {
        return years.map(({year}) => {
            const clazz = parseInt(year) === year_change
                ? 'm-1 btn btn-danger btn-sm'
                : 'm-1 btn btn-info btn-rg btn-sm';

            const link = `${getLink(year)}?${dispatch(getLinkData({page: 1}))}`;

            return (
                <button key={year} type="button" onClick={() => navigate(link)} className={clazz}>
                    {year}
                </button>
            );
        });
    }, [years, year_change, dispatch, getLink, navigate]);

    const {months} = find(years, {'year': String(year_change)});

    const createButtonMonth = useMemo(() => {

        return Object.keys(months).map((key) => {
            const {monthNameUserFormat, monthName} = months[key];
            const clazz = monthName === month_change
                ? 'm-1 btn btn-danger btn-sm'
                : 'm-1 btn btn-info btn-rg btn-sm';

            return (
                <button key={monthName} type="button"
                        onClick={() => navigate(`?${dispatch(getLinkData({month: monthName, page: 1}))}`)}
                        className={clazz}>
                    {monthNameUserFormat}
                </button>
            );
        });
    }, [months, month_change, year_change, dispatch, navigate]);

    if (user_lots_type === 'archive_lots') {
        return (
            <div className='row'>
                <div className="col-12">
                    {createButton}
                </div>
                <div className="col-12">
                    {createButtonMonth}
                </div>
            </div>
        );
    }

    return null;
}

export default YearsNavPanel;
