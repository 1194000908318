import {SET_DATAlINK_SIDEBAR_ARCHIVE} from "../../types";

const initialState = {
dataLink: []
}

export const sidebarArchiveReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATAlINK_SIDEBAR_ARCHIVE:
            return {...state, dataLink: action.payload};
        default:
            return state;
    }
}