import React from "react";
import './modal-header.css'
import {useDispatch, useSelector} from "react-redux";


import {onToggleAllLots} from "../../../../../redux/modals/modal-inline-mod-check/actionModal";

const ModalHeader = () => {

    const dispatch = useDispatch();

    const {ak_au_phrase_allocated_lots, select_all} = useSelector((state) => {
        return state.lots.phrases
    });

    const lotsData = useSelector((state) => {
        return state.lots.lotsData
    });

    const changeLots = useSelector((state) => {
        return state.modal.changeLots
    });

    const checkAllLots = changeLots.length === lotsData.length;

    return (
        <div className="modal-header flex-wrap-reverse">

            <h5 className="modal-title text-muted">
                {ak_au_phrase_allocated_lots}: {changeLots.length}
            </h5>

            <div className="custom-control custom-checkbox">

                <input checked={checkAllLots}
                       onChange={() => dispatch(onToggleAllLots(!checkAllLots))}
                       className="custom-control-input"
                       id="select_all"
                       type="checkbox"
                />

                <label className="custom-control-label text-muted" htmlFor="select_all">
                    {select_all}
                </label>
            </div>
        </div>
    );
};

export default ModalHeader