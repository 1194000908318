import BaseApiService from "./base-api-service";
export default class SubscriptionsApiService extends BaseApiService {
    constructor() {
        super();
        this._apiBase = `${this._baseUrl}/subscriptions`;
    }

    getDataSubscriptions = async (obj) => {
        return await this.getResource(``, obj);
    }

    setSubscriptionCategory = async (obj) => {
        return await this.getResource(`/follow-category`, obj, 'POST');
    }
    setSubscriptionPrefix = async (obj) => {
        return await this.getResource(`/follow-prefix`, obj, 'POST');
    }

}