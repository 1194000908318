// отправлять сюда в пропсах
// all_categories = все категории аукциона
// onChangeCategory = функцию которая возвращает категорию
// show = по дефолту отправляь true для того чтоб блок отобразился

import React from "react";
import {find} from "lodash/collection";
import categoriesData from "../hoc-helper/categories-data";
import {useSelector} from "react-redux";

const CategoriesBlock = (props) => {

    const {ak_au_ph_choose_category} = useSelector((state) => {
        return state.lots.phrases
    });
    const {handler_type} = useSelector((state) => {
        return state.modal
    });

    if (handler_type !== 5) {
        return null;
    }

    const {categoriesComponents} = props;

    const makeCategoriesBlock = ({categories, parent_category_id, onDelete, onChangeFunc}) => {

        const categoryChange = (e) => {
            const c_category_id = parseInt(e.target.value);
            const parent_category = find(categories, {'auction_category_id': c_category_id});
            const p_category_id = parseInt(parent_category.parent_category_id);

            onChangeFunc(c_category_id, p_category_id)
        };

        const makeOptionCategory = categories.map(({auction_category_id, category_title}) => {
            return (
                <option key={auction_category_id} value={auction_category_id}>{category_title}</option>
            )
        });

        const buttonDelete = (parent_category_id === 0) ? null :
            <button onClick={() => {
                onDelete(parent_category_id);
            }} type="button" className="close"
                    data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>;


        return (
            <div key={parent_category_id} className="col-12 my-2 d-flex">
                <select defaultValue={0} onChange={(e) => categoryChange(e)}
                        className="form-control mr-2">
                    <option disabled={true} value={0} key={parent_category_id}>
                        {ak_au_ph_choose_category}
                    </option>
                    {makeOptionCategory}
                </select>
                {buttonDelete}
            </div>
        );
    };


    return (
        categoriesComponents.map((item) => {
            return makeCategoriesBlock({...item},)
        })
    );
};

export default categoriesData(CategoriesBlock)