import {SET_DATA_REQUISITES, SET_DELIVERY_INPUTS, SET_ERROR_FORM, SET_PAYMENT_INPUTS} from "../types";


const initialState = {
    deliveryMethods: {},
    paymentMethods: {},
    csrfToken: '',
    phrases: {},
    loading: true,
    deliveryInputs: {},
    paymentInputs:{},
    errorForms: {}
};

export const requisitesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA_REQUISITES:
            return {...state, ...action.payload};
        case SET_DELIVERY_INPUTS:
            return {...state, deliveryInputs: {...state.deliveryInputs, ...action.payload}}
        case SET_PAYMENT_INPUTS:
            return {...state, paymentInputs: {...state.paymentInputs, ...action.payload}}
        case SET_ERROR_FORM:
            return {...state, errorForms: {...state.errorForms, ...action.payload}}
        default:
            return state
    }
}