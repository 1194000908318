import React from "react"
import {useSelector} from "react-redux";

const RssButton = () => {
    const {phrases: {rss}} = useSelector(state => state.lots);

    return (
        <div className="ml-1">
            <a className="btn btn-primary btn-sm btn-rg rsslink" title='rss' href="/auctions/index.rss" role="button">{rss}</a>
        </div>
    );
}
export default RssButton