import {SET_PAGE_NAV_DATA} from "../types";


const initialState = {
    firstPage: 1,
    lastPage: 1,
    activePage: 1,
    showFrom: 2,
    showTo: 5,
    defaultViewPage: 5,
    step: 2,
}

export const pageNavReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PAGE_NAV_DATA:
            return {...state, ...action.payload};
        default:
            return state
    }
}