import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setLotsData} from "../../redux/lots/action";
import {getFilters, setFilters} from "../../redux/sidebars/filter-sidebar/actionFilterSideBar";
import {getQueryLinkParams} from "../../helpers/routing-helper";
import useBreadCrumbsMainPage from "../global-hooks/breadcrums-hooks/useBreadCrumbsMainPage";
import useBreadCrumbsOtherPage from "../global-hooks/breadcrums-hooks/useBreadCrumbsOtherPage";
import {useLocation} from "react-router-dom";
import {clearChangeLot, toggleModal} from "../../redux/modals/modal-inline-mod-check/actionModal";
import useLotsDataWatcher from "../global-hooks/breadcrums-hooks/useLotsDataWatcher";

const useLotsFn = () => {
    const dispatch = useDispatch();

    const location = useLocation();
    const {search: urlSearch, pathname} = location;

    // ��������� ������ ����������� ������ �� state
    const {startLoading, simulateLoading, user_sort_data, changeLots} = useSelector(state => ({
        startLoading: state.lots.startLoading,
        simulateLoading: state.lots.simulateLoading,
        user_sort_data: state.lots.user_sort_data,
        changeLots: state.modal.changeLots
    }));

    // ����������� query, ����� �������� ����������
    const {user_lots_type = ''} = useMemo(() => getQueryLinkParams(user_sort_data), [user_sort_data, urlSearch, pathname]);

    useEffect(() => {
        const initializeData = async () => {
            const filters = await dispatch(getFilters());
            await dispatch(setFilters(filters));
            await dispatch(setLotsData());
        };

        initializeData()
    }, [urlSearch, pathname]);

    useLotsDataWatcher()
    useBreadCrumbsMainPage();
    useBreadCrumbsOtherPage();

    // ������ ��� ��������� �����
    useEffect(() => {
        // dispatch(changeAllLots(changeLots.length === lotsData.length));
        if (changeLots.length === 0) {
            dispatch(toggleModal(false));
        }
    }, [changeLots]);

    useEffect(() => {
        dispatch(clearChangeLot());
    }, [urlSearch, pathname]);


    return {
        startLoading, simulateLoading, user_lots_type,
    }
}
export default useLotsFn
