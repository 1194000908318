import {combineReducers} from 'redux'
import {lotsReducer} from "./lots/lotsReducer";
import {modalReducer} from "./modals/modal-inline-mod-check/modalReducer";
import {NewPopularEndingSideBarReducer} from "./sidebars/new-popular-ending-sidebar/newPopularEndingSideBarReducer";
import {sideBarReducer} from "./sidebars/sideBarReducer";
import {userSideBarReducer} from "./sidebars/user-sidebar/userSideBarReducer";
import {filterSideBarReducer} from "./sidebars/filter-sidebar/FilterSideBarReducer";
import {categoriesSidebarReducer} from "./sidebars/categories-sidebar/CategoriesSidebarReducer";
import {auctionModeratorsSideBarReducer} from "./sidebars/sidebar-auction-moderators/AuctionModeratorsSideBarReducer";
import {onlineUsersSidebarReducer} from "./sidebars/sidebar-online-users/OnlineUsersSidebarReducer";
import {SideBarStatsReducer} from "./sidebars/sidebar-stats/SideBarStatsReducer";
import {sidebarArchiveReducer} from "./sidebars/sidebar-archive/sidebarArchiveReducer";
import {interestingLotsReducer} from "./top-bar/interesting-lots/interestingLotsReducer";
import {modalPrefixReducer} from "./modals/modal-prefix/modalPrefixReducer";
import {promotionLotsReducer} from "./modals/modal-promotion-lots/promotionLotsReducer";
import {sortingSettingsReducer} from "./modals/modal-sorting-settings/SortingSettingsReducer";
import {modalSetDateReducer} from "./modals/modal-set-date/modalSetDateReducer";


import {modalTradingDurationReducer} from "./modals/modal-trading-duration/modalTradingDurationReducer";
import {categorySettingsReducer} from "./modals/modal-category-settings/categorySettingsReducer";
import {requisitesReducer} from "./requisites/requisitesReducer";
import {modalSetEndTimeReducer} from "./modals/modal-set-end-time/modalSetEndTimeReducer";
import {crudLotReducer} from "./crud-lot/crudLotReducer";
import {lotPageReducer} from "./lot-page/lotPageReducer"
import {modalSetTitleReducer} from "./modals/modal-set-title/modalSetTitleReducer";
import {modalRemoveLotReducer} from "./modals/modal-remove-lot/modalRemoveLotReducer";
import {modalFinishLotReducer} from "./modals/modal-finish-lot/modalFinishLotReducer";
import {modalRemoveLotFromSaleReducer} from "./modals/modal-remove-from-sale/RemoveLotFromSaleReducer";
import {modalLotLogsReducer} from "./modals/modal-lot-logs/modalLotLogsReducer";
import {modalEditPriceReducer} from "./modals/modal-edit-price/modalEditPriceReducer";
import {additionalInfoReducer} from "./modals/modal-add-additional-info/additionalInfoReducer";
import {imageGalleryReducer} from "./modals/modal-image-gallery/imageGalleryReducer";
import {replyQuestionReducer} from "./modals/modal-reply-question/replyQuestionReducer";
import {penaltyForMessageReducer} from "./modals/modal-add-penalty-for-message/penaltyForMessageReducer";
import {disputReducer} from "./disput/disputReducer";
import {modalViewLotSaleReducer} from "./modals/modal-view-lot-sale/modalViewLotSaleReducer";
import {editLotQuantityReducer} from "./modals/modal-edit-lot-quantity/editLotQuantityReducer";
import {pageNavReducer} from "./page-nav/pageNavReducer";
import {modalSubscriptionCategoryReducer} from "./modals/modal-subscription-category/modalSubscriptionCategoryReducer";
import {modalSubscriptionPrefixReducer} from "./modals/modal-subscription-prefix/modalSubscriptionPrefixReducer";
import {subscriptionsReducer} from "./subscriptions/subscriptionsReducer";
import {modalCreateLotReducer} from "./modals/modal-create-lot/modalCreateLotReducer";


const createRootReducer = () => combineReducers({

    //lots component
    lots: lotsReducer,
    modal: modalReducer,
    modalRemoveLot: modalRemoveLotReducer,
    modalRemoveFromSale: modalRemoveLotFromSaleReducer,
    modalLotLogs: modalLotLogsReducer,
    modalAdditionalInfo: additionalInfoReducer,
    modalFinishLot: modalFinishLotReducer,
    modalSetTitle: modalSetTitleReducer,
    modalPrefix: modalPrefixReducer,
    modalPromotion: promotionLotsReducer,
    modalSetDate: modalSetDateReducer,
    modalSetEndTime: modalSetEndTimeReducer,
    modalTradingDuration: modalTradingDurationReducer,
    modalEditPrice: modalEditPriceReducer,
    sortingSetting: sortingSettingsReducer,
    modalQuestion: replyQuestionReducer,
    modalPenaltyForMessage: penaltyForMessageReducer,
    modalViewLotSale: modalViewLotSaleReducer,
    modalCreateLot: modalCreateLotReducer,
    editLotQuantity:editLotQuantityReducer,
    disput: disputReducer,
    imageGalleryReducer: imageGalleryReducer,
    //left sidebar
    sideBarNPE: NewPopularEndingSideBarReducer,
    sidebar: sideBarReducer,
    userSidebar: userSideBarReducer,
    filterSidebar: filterSideBarReducer,
    categoriesSidebar: categoriesSidebarReducer,
    auctionModeratorsSidebar: auctionModeratorsSideBarReducer,
    onlineUsersSidebar: onlineUsersSidebarReducer,
    sidebarStats: SideBarStatsReducer,
    sidebarArchiveReducer: sidebarArchiveReducer,
    //topBar
    interestingLotsReducer: interestingLotsReducer,
    categorySettingsReducer: categorySettingsReducer,
    //Requisites
    requisites: requisitesReducer,
    //crud
    crud: crudLotReducer,
    //lotPage
    lotPage: lotPageReducer,
    //
    pageNav:pageNavReducer,
    //subscriptions
    subscriptions:subscriptionsReducer,
    modalSubscriptionCategory: modalSubscriptionCategoryReducer,
    modalSubscriptionPrefix: modalSubscriptionPrefixReducer,
})
export default createRootReducer