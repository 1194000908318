import React, {useMemo} from 'react'
import {useDispatch} from "react-redux";
import useTopBarButtonFn from "../useTopBarButtonFn";
import {find} from "lodash/collection";
import {isUndefined} from "lodash/lang";
import {setParamsSubscriptionPrefixModal} from "../../../../redux/subscriptions/actionSubscriptions";


const SubscriptionCategoryPrefix = () => {

    const dispatch = useDispatch();
    const {phrases: {ak_au_follow_prefix, ak_au_unfollow_prefix}, user_prefixes, prefix_id, visitor_user_id} = useTopBarButtonFn();

    const buttonPhrase = useMemo(() => {

        if (user_prefixes.length !== 0) {
            const prefixData = find(user_prefixes, {prefix_id: parseInt(prefix_id)});

            if (isUndefined(prefixData)){
                return  ak_au_follow_prefix;
            }else {
                return (visitor_user_id === prefixData.user_id) ? ak_au_unfollow_prefix : ak_au_follow_prefix;
            }
        }

        return ak_au_follow_prefix;

    }, [user_prefixes, prefix_id, visitor_user_id])

    const setShowModal = () => {
        dispatch(setParamsSubscriptionPrefixModal())
    }

    return (
        <div className="ml-1">
            <button type="button" onClick={() => setShowModal()} className="btn btn-success btn-sm btn-rg">
                {buttonPhrase}
            </button>
        </div>
    )

}
export default SubscriptionCategoryPrefix