import React, {useContext, useMemo} from 'react'
import Countdown from "../../../../lot-components/countdown";
import DayOffBlock from "../../../../lot-components/day-off-block";
import LotStatusBlock from "../../../../lot-components/lot-status-block";
import BuyersBlock from "../../../../lot-components/buyers-block";
import SellerBlock from "../../../../lot-components/seller-block";
import {LotsContext} from "../../../auction-lots-listing";
import {lotContext} from "../lots-list-item"
import {includes} from "lodash/collection";
import {getQueryLinkParams} from "../../../../../helpers/routing-helper";


const ThirdBlock = () => {
    const {
        visitor: {option_display_end_time},
        phrases: {expired},
        type, user_lots_type
    } = useContext(LotsContext);

    const {lotData} = useContext(lotContext);
    const {expiration_date, status, auction_id} = lotData;

    return useMemo(() => {
        if (type === 'no_sale') {
            return <DayOffBlock/>;
        }

        if (user_lots_type === 'archive_lots') {
            return <DayOffBlock/>;
        }

        // ��� ����������� ����� ��� ��� ������ ������������
        if (type === 'all_user_bets') {
            return (status === 'active')
                ? <Countdown date={expiration_date}/>
                : <div key={auction_id}
                       className='d-flex flex-wrap justify-content-center text-muted align-items-sm-center'>
                    {expired}</div>
        }

        if (includes(['active', 'vacation'], status)) {
            return (option_display_end_time || option_display_end_time === undefined)
                ? <Countdown date={expiration_date}/>
                : <DayOffBlock/>
        }

        if (includes(['nosale', 'finalized', 'inactive_copy'], status)) {
            return <LotStatusBlock/>;
        }

        if (status === 'send') {
            return (type !== 'send') ? <BuyersBlock/> : <SellerBlock/>
        }

        if (includes(['sale', 'waitforpay', 'take'], status)) {
            return includes(['sale', 'awaiting_payment', 'awaiting_confirmation'], type)
                ? <BuyersBlock/>
                : <SellerBlock/>;
        }

        if (status === 'no_pay') {
            return <BuyersBlock/>;
        }

        return null
    }, [type, status, user_lots_type]);
}
export default ThirdBlock