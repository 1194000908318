import {SET_DATA_FINISH_LOT_MODAL} from "../../types";

const initialState = {
    modalShow: false
}

export const modalFinishLotReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_DATA_FINISH_LOT_MODAL :
            return {...state, ...action.payload};
        default:
            return state;
    }

}