import {SET_PARAMS_LOT_LOGS} from "../../types";

const initialState = {
    modalShow: false,
    auction_id: 0,
    action: '',
    logs: []
};

export const modalLotLogsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PARAMS_LOT_LOGS:
            return {...state, ...action.payload};
        default:
            return state
    }
}