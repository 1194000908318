import {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getQueryLinkParams} from "../../../helpers/routing-helper";
import find from 'lodash/find';
import {isEmpty} from "lodash/lang";
import {getAllParentsByCategoryId} from "../../../redux/lots/action";
import GlobalHelper from "../../../helpers/global-helper";

const useBreadCrumbsMainPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        auction_description, ak_au_auction_title, auctions, all_categories, auction_category_id, user_lots_type
    } = useSelector(state => {
        const { xenOptions: {auction_description}, phrases: {ak_au_auction_title, auctions}, all_categories} = state.lots;
        const {auction_category_id, user_lots_type} = getQueryLinkParams();
        return {
            auction_description, ak_au_auction_title, auctions, all_categories, auction_category_id, user_lots_type
        }
    });

    useEffect(() => {
        if (user_lots_type === 'auction_lots' && !isEmpty(all_categories)) {
            setBreadCrumbs();
        }

    }, [user_lots_type, all_categories]);

   const setBreadCrumbs = () => {
            let title = ak_au_auction_title;
            let secondTitle = ak_au_auction_title;
            let description = auction_description;

            let lotsBreadCrumbs = [
                {
                    'href': '/',
                    'value': auctions,
                    action: () => {
                        navigate('/');
                    }
                }
            ];

            if (auction_category_id !== 0) {
                const {category_description, category_title, second_title} = find(all_categories, {auction_category_id});

                title = category_title;
                secondTitle = isEmpty(second_title) ? category_title : second_title;
                description = category_description;

                let res = dispatch(getAllParentsByCategoryId(auction_category_id)).reverse();
                res.map((item, key) => {
                    const linkHref = `/auctions/${GlobalHelper.getCategoryVariableReference(item)}`;
                    lotsBreadCrumbs.push({
                        'href': linkHref,
                        'value': item.category_title,
                        action: () => {
                            navigate(linkHref);
                        }
                    });
                    return true;
                });
            }

            document.title = `${title} | REIBERT.info`;

            let xenTitle = document.querySelector('div.titleBar h1');
            let pageDescription = document.querySelector('#pageDescription');

            if (xenTitle !== null) {
                xenTitle.innerText = secondTitle;
            }

            if (pageDescription !== null) {
                pageDescription.innerText = description;
            }

            const breadcrumsTop = document.querySelector('.breadBoxTop nav fieldset.breadcrumb span.crumbs');
            const breadcrumsBottom = document.querySelector('.breadBoxBottom nav fieldset.breadcrumb span.crumbs');

            if (breadcrumsTop !== null && breadcrumsBottom !== null) {
                GlobalHelper.makeBreadCrumb(breadcrumsTop, lotsBreadCrumbs);
                GlobalHelper.makeBreadCrumb(breadcrumsBottom, lotsBreadCrumbs);
            }
        }


    // ���������� ������� � ������� useCallback
    // return useCallback(() => {
    //     let title = ak_au_auction_title;
    //     let secondTitle = ak_au_auction_title;
    //     let description = auction_description;
    //
    //     let lotsBreadCrumbs = [
    //         {
    //             'href': '/',
    //             'value': auctions,
    //             action: () => {
    //                 navigate('/');
    //             }
    //         }
    //     ];
    //
    //     if (auction_category_id !== 0) {
    //         const {category_description, category_title, second_title} = find(all_categories, {auction_category_id});
    //
    //         title = category_title;
    //         secondTitle = isEmpty(second_title) ? category_title : second_title;
    //         description = category_description;
    //
    //         let res = dispatch(getAllParentsByCategoryId(auction_category_id)).reverse();
    //         res.map((item, key) => {
    //             const linkHref = `/auctions/${GlobalHelper.getCategoryVariableReference(item)}`;
    //             lotsBreadCrumbs.push({
    //                 'href': linkHref,
    //                 'value': item.category_title,
    //                 action: () => {
    //                     navigate(linkHref);
    //                 }
    //             });
    //             return true;
    //         });
    //     }
    //
    //     document.title = `${title} | REIBERT.info`;
    //
    //     let xenTitle = document.querySelector('div.titleBar h1');
    //     let pageDescription = document.querySelector('#pageDescription');
    //
    //     if (xenTitle !== null) {
    //         xenTitle.innerText = secondTitle;
    //     }
    //
    //     if (pageDescription !== null) {
    //         pageDescription.innerText = description;
    //     }
    //
    //     const breadcrumsTop = document.querySelector('.breadBoxTop nav fieldset.breadcrumb span.crumbs');
    //     const breadcrumsBottom = document.querySelector('.breadBoxBottom nav fieldset.breadcrumb span.crumbs');
    //
    //     if (breadcrumsTop !== null && breadcrumsBottom !== null) {
    //         GlobalHelper.makeBreadCrumb(breadcrumsTop, lotsBreadCrumbs);
    //         GlobalHelper.makeBreadCrumb(breadcrumsBottom, lotsBreadCrumbs);
    //     }
    // }, [
    //     all_categories,
    //     auction_category_id,
    // ]);
};

export default useBreadCrumbsMainPage;
