import {SET_DATA_REMOVE_LOT_MODAL} from "../../types";

const initialState = {
    modalShow: false,
    delete_type: null,
    delete_reason: ''
}

export const modalRemoveLotReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_DATA_REMOVE_LOT_MODAL :
            return {...state, ...action.payload};
        default: return state;
    }

}