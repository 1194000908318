import React, {useMemo} from 'react'
import WithSuspense from "../../repeating-components/wrapper-components/with-suspense";
import {Form} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {uk} from "date-fns/locale";

import './modal-set-date.css';
import "react-datepicker/dist/react-datepicker.css";

import {useDispatch, useSelector} from "react-redux";
import {setParamsSetDateModal} from "../../../redux/modals/modal-set-date/actionSetDate";
const ModalBlackBackground = React.lazy(() => import('../../repeating-components/wrapper-components/modal-black-background'));

const ModalContentSetDate = (props) => {

    const dispatch = useDispatch();

    const {phrases: {ak_au_ph_edit_end_date, save}, xenOptions:{
        options_day_rang
    }} = useSelector(state => {
        return state.lots
    })

    const {sendForm} = props

    const {modalShow, newChosenDate} = useSelector(state => {
        return state.modalSetDate
    });

    const setParams = (data) => {
        dispatch(setParamsSetDateModal(data));
    }

    const handleShow = () => {
        setParams({modalShow: !modalShow});
    }

    const onChangeDate = (val) => {
        setParams({newChosenDate: val});
    }

    const getMinMaxDate = useMemo(() => {
        const minMaxDay = options_day_rang.split('-');

        const day = 86400000;

        const minDaySex = minMaxDay[0] * day;
        const maxDaySec = minMaxDay[1] * day;

        const now = new Date();
        const minDate = new Date(now.getTime() + minDaySex);
        const maxDate = new Date(now.getTime() + maxDaySec);

        return {minDate, maxDate};
    }, [options_day_rang]);

    const modalContent = useMemo(() => {

        const {minDate, maxDate} = getMinMaxDate;

        return (
            <Form.Row className={'p-md-4 justify-content-center'}>
                <Form.Group className={`col-md-5`}>
                    <DatePicker
                        customInput={
                            <Form.Control required size={'sm'} className={'text-center text-dark'} name="end_time"
                                          id="inputEndTime" type="text"/>
                        }
                        className='bg-white text-white'
                        onChange={(date) => onChangeDate(date)}
                        locale={uk}
                        selected={newChosenDate}
                        dateFormat={"dd.MM.yyyy"}

                        maxDate={maxDate}
                        minDate={minDate}
                    />
                </Form.Group>
            </Form.Row>
        )
    }, [getMinMaxDate, newChosenDate])

    return (
        <WithSuspense>
            <ModalBlackBackground
                setShow={handleShow}
                show={modalShow}
                sendForm={sendForm}
                phraseTitle={ak_au_ph_edit_end_date}
                phraseSubmit={save}
            >
                {modalContent}
            </ModalBlackBackground>
        </WithSuspense>
    )
}
export default ModalContentSetDate