import {SET_CATEGORIES, SET_SHOW_BLOCK_CATEGORIES} from "../../types";

const initialState = {
    categories: {},
    show: true
}

export const categoriesSidebarReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_CATEGORIES:
            return {...state, categories: action.payload}
        case SET_SHOW_BLOCK_CATEGORIES:
            return {...state, show: action.payload}
        default:
            return state;
    }
}