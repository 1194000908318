import {SET_PARAMS_PROMOTION_LOTS} from "../../types";
import {findIndex} from "lodash/array";
import {sumBy} from "lodash/math";
import {cloneDeep, isNull} from "lodash/lang";
import {setLotsData} from "../../lots/action";
import PromotionApiService from "../../../services/promotion-api-service";
import InlineModApiService from "../../../services/inline-mod-api-service";
import {matchPath} from "react-router-dom";
import {getLotData} from "../../lot-page/action";

export function setParamsPromotionLots(data) {
    return dispatch => {
        dispatch({type: SET_PARAMS_PROMOTION_LOTS, payload: data})
    }
}

export function setPromotionData(data, type) {
    return dispatch => {
        if (type) {
            dispatch(setPromotionElemet(data))
            return true
        }

        dispatch(deletePromotionElement(data))
    }
}

export function setPromotionElemet(data) {
    return (dispatch, getState) => {
        const {promotionData} = getState().modalPromotion

        let newData = cloneDeep(promotionData);
        const index = findIndex(newData, {auction_id: data.auction_id});

        if (index === -1) {
            newData = [...newData, data]
        } else {
            newData[index] = data
        }

        const total = sumBy(newData, (o) => {
            return o.cost
        })

        const obj = {
            promotionData: newData,
            total,

        }
        dispatch(setParamsPromotionLots(obj))
    }
}

export function deletePromotionElement(data) {
    return (dispatch, getState) => {
        const {promotionData} = getState().modalPromotion

        let newData = cloneDeep(promotionData);
        const index = findIndex(newData, {auction_id: data.auction_id});

        newData = [...newData.slice(0, index), ...newData.slice(index + 1)];
        const total = sumBy(newData, (o) => {
            return o.cost
        })

        const obj = {
            promotionData: newData,
            total,
        }
        dispatch(setParamsPromotionLots(obj))
    }
}

export function sendFormPromotion() {
    return (dispatch, getState) => {
        const {setPromotionLots} = new PromotionApiService();
        const {promotionData} = getState().modalPromotion
        const obj = {promotions: JSON.stringify(promotionData)}

        setPromotionLots(obj).then(json => {

            if ('error' in json) {
                alert(json.error);
                return
            }

            const location = window.location.pathname
            const match = matchPath("/auction/:user_id/:type?", location);

            if ((match && !isNull(match)) || location === '/') {
                dispatch(setLotsData())
            } else {
                dispatch(getLotData());
            }

            const obj = _defaultStateData()
            dispatch(setParamsPromotionLots(obj))
        });
    }
}

export default function setPromotionModalData(obj){
    return async (dispatch) => {
        const {setParamsInlineBlock} = new InlineModApiService();

        setParamsInlineBlock(obj).then((json) => {
            json.modalShow = true
            dispatch(setParamsPromotionLots(json))
        })
    }
}

export function _defaultStateData() {
    const obj = {
        modalShow: false,
        maxPeriod: false,
        error: false,
        total: 0,
        promotionData: [],
        promotionLots: {}
    }

    return obj
}