import {SET_DATA_CATEGORY_SETTINGS} from "../../types";

const initialState = {
    modalShow: false,
    promotion_price: null,
    percent_sale: null,
    cost_placing_bet: null,
    pay_for_reedit_lot: null,
    min_start_price: null,
    min_percent: null,
    max_percent: null,
};

export const categorySettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA_CATEGORY_SETTINGS:
            return {...state, ...action.payload};
        default:
            return state
    }
}