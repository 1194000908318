import {SET_DATA_ADDITIONAL_INFO} from "../../types";

const initialState = {
    modalShow: false,
    message: '',
    auction_id: null,
    attachments: [],
    attachmentParams: {},
    additional_info_id: null
};

export const additionalInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA_ADDITIONAL_INFO:
            return {...state, ...action.payload};
        default:
            return state
    }
}