import ReibertApiService from "../../../services/reibert-api-service";
import {SET_DATA_CATEGORY_SETTINGS} from "../../types";

const reibertApiService = new ReibertApiService();

export function onChangeButtonCategorySettings(category_id) {
    return (dispatch) => {
        const {getUserCategorySettings} = reibertApiService;

        let obj = {category_id}
        getUserCategorySettings(obj).then(json => {

            const data = {...json, modalShow: true}

            dispatch(setDataCategorySettings(data))

        });
    }
}

export function setDataCategorySettings(data) {
    return (dispatch, getState) => {
        dispatch({type: SET_DATA_CATEGORY_SETTINGS, payload: data})
    }
}


export function _defaultStateData() {
    const obj = {
        modalShow: false,
        promotion_price: null,
        percent_sale: null,
        cost_placing_bet: null,
        pay_for_reedit_lot: null,
        min_start_price: null,
        min_percent: null,
        max_percent: null,
    }

    return obj
}