import BaseApiService from "./base-api-service";

export default class CrudApiService extends BaseApiService {
    constructor() {
        super();
        this._apiBase = `${this._baseUrl}/crud`;
    }

    getEditData = async (obj) => {
        return await this.getResource(`/get-edit-data`, obj);
    };

    getCreateData = async (obj) => {
        return await this.getResource(`/get-create-data`, obj);
    };

    uploadImages = async (formData) => {
        return await this.getResource(`/set-lot-images`, formData, 'POST');
    }

    deleteImage = async (obj) => {
        return await this.getResource(`/delete-image`, obj, 'POST');
    }

    getPromotionData = async (obj) => {
        return await this.getResource(`/get-promotion-data`, obj, 'POST');
    }

    sendFormCreateLot = async (obj) => {
        return await this.getResource(`/create-lot`, obj, 'POST');
    }

    sendFormEditLot = async (obj) => {
        return await this.getResource(`/save-lot`, obj, 'POST');
    }

}