import React from "react"
import {useDispatch, useSelector} from "react-redux";
import {onChangeAction} from "../../../../../redux/modals/modal-inline-mod-check/actionModal";

const ModalAction = () => {

    const dispatch = useDispatch();

    const ak_au_ph_choose_an_action = useSelector((state) => {
        return state.lots.phrases.ak_au_ph_choose_an_action
    });

    const option = useSelector((state) => {
        return state.modal.option
    });


    //�������� ����������� ����� ��� �������
    const makeOption = option.map(({view, phrase, value}) => {
        if (view()) {
            return (
                <option key={value} value={value}>{phrase}</option>
            );
        } else return null
    });

    return (
        <div className="col-12 my-2">
            <select defaultValue={0} id="select_action"
                    className="form-control my-1"
                    onChange={(e) => dispatch(onChangeAction(parseInt(e.target.value)))}>
                <option disabled={true} value={0} key={0}>
                    {ak_au_ph_choose_an_action}
                </option>
                {makeOption}
            </select>
        </div>
    )

}

export default ModalAction