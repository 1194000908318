import BaseApiService from "./base-api-service";


export default class PromotionApiService extends BaseApiService{
    constructor() {
        super();
        this._apiBase = `${this._baseUrl}/promotion`;
    }

    getDataAutoPromotionForm = async (obj) => {
        return await this.getResource(`/get-data-auto-promotion-form`, obj);
    }

    setAutoPromotion = async (obj) => {
        return await this.getResource(`/switching-on-auto-promotion`, obj, 'POST');
    }

    disabledAutoPromotion = async (obj) => {
        return await this.getResource(`/switching-off-auto-promotion`, obj, 'POST');
    }

    setPromotionLots = async (obj) => {
        return await this.getResource(`/addPromotionLots`, obj, 'POST');
    }
}