import React, {useContext} from 'react'
import {find} from "lodash/collection";
import GlobalHelper from "../../../helpers/global-helper";
import {getArchiveSwichData} from "../../../redux/archive/actionArchive";
import {getLinkData} from "../../../redux/lots/action";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {LotsContext} from "../../auction-lots-listing/auction-lots-listing";
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";

const PrefixBlock = () => {

    const dispatch = useDispatch();
    const {auction_categories, phrases, user_lots_type, year, type}  = useContext(LotsContext);
    const css_classes_prefix = useSelector(state => state.lots.css_classes_prefix);
    const {lotData} = useContext(lotContext);
    const {prefix_id, auction_category_id} = lotData
    const {show_only_threads_prefixed_by_x} = phrases;

    if (auction_category_id === 0){
        return null
    }

    const item = find(auction_categories, {auction_category_id})

    let pathname = `/auctions/${GlobalHelper.getCategoryVariableReference(item)}`;
    let search = `?prefix_id=${prefix_id}`;

    if (user_lots_type === 'archive_lots') {
        const {link} = find(dispatch(getArchiveSwichData(year)), {type});
        pathname = `${link}${GlobalHelper.getCategoryVariableReference(item)}`;
        search = dispatch(getLinkData({prefix_id}));
    }

    const paramsTo = {
        pathname: pathname,
        search: search
    }

    return (
        <Link className="prefixLink mx-1" data-action
              title={show_only_threads_prefixed_by_x.replace(/\{prefix\}/, phrases[`category_prefix_${prefix_id}`])}
              to={paramsTo}
        >
                <span data-action className={css_classes_prefix[prefix_id]}>
                    {phrases[`category_prefix_${prefix_id}`]}
                </span>
        </Link>
    );

}
export default PrefixBlock