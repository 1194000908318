import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {onChangeCopyAction} from "../../../../../redux/modals/modal-inline-mod-check/actionModal";

const CopyBlock = () => {

    const dispatch = useDispatch();

    const {
        phrases: {
            ak_au_ph_choose_an_action,
            ak_au_make_copies_and_put_up_for_sale,
            ak_au_create_copies_and_leave_in_unsold_for_editing
        }
    } = useSelector((state) => {
        return state.lots
    });

    const handler_type = useSelector((state) => {
        return state.modal.handler_type
    });

    if (handler_type !== 11) {
        return null;
    }

    return (
        <div className="col-12 my-2">
            <select defaultValue={0} className="form-control" required
                    onChange={(e) => dispatch(onChangeCopyAction(parseInt(e.target.value)))}>
                <option disabled={true} value={0}>{ak_au_ph_choose_an_action}</option>
                <option value={1}>{ak_au_make_copies_and_put_up_for_sale}</option>
                <option value={2}>{ak_au_create_copies_and_leave_in_unsold_for_editing}</option>
            </select>
        </div>
    );
}

export default CopyBlock