import {SET_ATTACHMENTS_FORM_DISPUT_MESSAGE, SET_LOT_DATA, UPDATE_AUCTION_OPTION} from "../types";

const initialState = {
    attachmentsDisputeMessages: {}, // { id_message(int): [{arrAttachments}, {arrAttachments}] }
    attachmentsFormDisputeMessage: [], // [{arrAttachments}, {arrAttachments}]

    user: {},
    auction: {
        auto_reedit_lot: false,
        auto_promotion_lot: false,
        allow_offer_price: false,
        only_positive_balance: false,
        only_not_ignore: false,
        only_over_one_month: false,
    },
    topBidderData: {},
    lotHistory: [],
    allBids: [],
    autoBids: [],
    max_autobid: {},
    offers: [],
    questions: {},

    // all_categories: {},
    count_watch_users: 0,
    watch_users: [],
    allCountViewerLot: 0,
    ignore_view: 0,
    view_offer_button: 0,
    lotPaymentMethods: [],
    lotDeliveryMethods: [],

    //disput
    disput: {},
    watched_dispute: [],
    disput_messages: [],
    disput_history: [],

    finish_time_for_pay: 0,
    finish_time_for_send: 0,
    finish_time_for_take: 0,

    payment_creating_copy: 0,
    pay_for_reedit_lot: 0,
    last_max_bid: false,

    promotionPrice: 0,

    loadingPage: true
}

export const lotPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ATTACHMENTS_FORM_DISPUT_MESSAGE:
            return {...state, attachmentsFormDisputeMessage: action.payload};
        case SET_LOT_DATA:
            return {...state, ...action.payload}
        case UPDATE_AUCTION_OPTION:
            return {
                ...state,
                auction: {
                    ...state.auction,
                    ...action.payload,
                },
            };
        default:
            return state;
    }
}