import {SET_DATA_LOT_QUANTITY} from "../../types";

const initialState = {
    modalShow: false,
    auction: {},
    phrases: {},
    quantity: 0

};

export const editLotQuantityReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA_LOT_QUANTITY:
            return {...state, ...action.payload};
        default:
            return state
    }
}