import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {truncate} from "lodash/string";
import React from "react";
import CategoryLinkComponent from "../../repeating-components/wrapper-components/category-link-component";

const CreateCategoriesContent = (props) => {
    const {auction_category_id, category_title, category_description} = props

    if (auction_category_id === 56 || category_title == '������ � ����') {
        return null
    }

    return (
        <OverlayTrigger
            placement={'bottom'}
            overlay={
                <Tooltip id={`tooltip-${auction_category_id}`}>
                    {category_description}
                </Tooltip>
            }>
            <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4'>
                <div className="'d-flex flex-column m-2 alert alert-secondary">
                    <CategoryLinkComponent className={'font-weight-bold'} item={props}>
                        {category_title}
                    </CategoryLinkComponent>
                    <span className='d-none d-xl-block'>
                        {truncate(category_description, {'length': 54, 'separator': '...'})}
                    </span>
                </div>
            </div>
        </OverlayTrigger>
    )
}
export default CreateCategoriesContent