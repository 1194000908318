import React, {useContext} from 'react';
import BuyerOrSallerBlock from "../buyer-or-saler-block";
import {useSelector} from "react-redux";
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";
import {LotsContext} from "../../auction-lots-listing/auction-lots-listing";

const BuyersBlock = () => {

    const {phrases:{buyer}} = useContext(LotsContext);
    const {lotData:{top_bidder, top_bidder_name} } = useContext(lotContext);

    return (
        <BuyerOrSallerBlock
            word={buyer}
            name={top_bidder_name}
            user_id={top_bidder}
            rating={false}
        />
    );
}

export default BuyersBlock