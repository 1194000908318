import {SET_DEFAULT_PARAMS, SET_SIDEBAR_DEFAULT_LOADING_DATA} from "../types";

const initialState = {
    phrases:{},
    loading: true
}

export const sideBarReducer = (state = initialState, action) => {

    switch (action.type){
        case SET_DEFAULT_PARAMS:
            return {...state, ...action.payload};
        case SET_SIDEBAR_DEFAULT_LOADING_DATA:
            return {...state, loading:action.payload};
        default:
            return state;
    }

}