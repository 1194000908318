import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import configureStore from './redux/configureStore';
import './index.css';
import Lots from './components/lots';
import Modals from "./components/modals/modals";

const Requisites = React.lazy(() => import('./components/requisites'));
const LotPage = React.lazy(() => import('./components/lot-page'));
const CreateLot = React.lazy(() => import('./components/crud-lot/create-lot'));
const EditLot = React.lazy(() => import('./components/crud-lot/edit-lot'));
const Subscriptions = React.lazy(() => import('./components/subscriptions'));

const store = configureStore();
const root = createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
                <Modals/>
                <Routes>
                    <Route path="/" element={<Lots/>}/>
                    <Route path="/auctions/" element={<Lots/>}/>
                    <Route path="/requisites/" element={<Requisites/>}/>
                    <Route path="/auction-history/:action" element={<Lots/>}/>
                    <Route path="/auction/:user_id/:type?" element={<Lots/>}/>
                    <Route path="/auctions/:auction_category_id" element={<Lots/>}/>
                    <Route path="/archive/:year/:type/:auction_category_id?" element={<Lots/>}/>
                    <Route path="/auction-process/create/:auction_category_id?" element={<CreateLot/>}/>
                    <Route path="/auction-process/edit/:auction_id?" element={<EditLot/>}/>
                    <Route path="/lots/:auction_id?" element={<LotPage/>}/>
                    <Route path="/subscriptions/:action" element={<Subscriptions/>}/>
                </Routes>
            </Suspense>
        </BrowserRouter>
    </Provider>
);

