import React, {useContext, useMemo} from 'react';
import {has} from "lodash/object"
import {orderBy, filter} from "lodash/collection"
import {Button} from "react-bootstrap";
import './admin-lot-buttons.css';
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";
import {LotsContext} from "../../auction-lots-listing/auction-lots-listing";

const AdminLotButtons = () => {

    const {lotData:{auction_id, isdel, status, user_id, watch_user_id = null}} = useContext(lotContext);

    const {
        visitor: {user_id: visitor_user_id, permissions: {auctions: {RestoreLots, view_all_bids, editOthersAuctions, deleteLots}}, hidden_sellers},
        phrases: {
        ak_au_stop_watch_lot,
        ak_au_watch_lot,
        ak_au_del_lot,
        ak_au_edit,
        ak_au_relocation_lot,
        ak_au_lot_restore,
        hide
    }} = useContext(LotsContext);


    //todo переделать метод получения модального окна.
    const buttonsArr = useMemo(() => {

            const sameCondition = visitor_user_id && !has(hidden_sellers, user_id) && status === 'active';
            const notWatcher = visitor_user_id !== watch_user_id;

            const buttonsOption = [
                {
                    phrase: ak_au_watch_lot,
                    order: 1,
                    conditions: (notWatcher && sameCondition),
                    action: () => {
                        window.XenForo.getXenForoModal(
                            'auction-process/follow-auction-lot', {auction_id}, 'GET')
                    }
                },
                {
                    phrase: ak_au_stop_watch_lot,
                    order: 1,
                    conditions: (!notWatcher && sameCondition),
                    action: () => {
                        window.XenForo.getXenForoModal(
                            'auction-process/follow-auction-lot', {auction_id}, 'GET')
                    }
                },
                {
                    phrase: hide,
                    order: 4,
                    conditions: ((visitor_user_id !== user_id) && sameCondition),
                    action: () => {
                        window.XenForo.getXenForoModal(
                            'account/HideAllSellersLots', {user_id, auction_id}, 'GET')
                    }
                },
                {
                    phrase: ak_au_del_lot,
                    order: 2,
                    conditions: deleteLots && isdel === 0,
                    action: () => window.XenForo.getXenForoModal(
                        'auction-process/formdelete', {id: auction_id}, 'GET')
                },
                {
                    phrase: ak_au_lot_restore,
                    order: 2,
                    conditions: (RestoreLots && isdel === 1),
                    action: () => window.XenForo.getXenForoModal(
                        'auction-process/restore', {id: auction_id}, 'GET')
                },
                {
                    phrase: ak_au_relocation_lot,
                    order: 3,
                    conditions: (view_all_bids && status === 'active'),
                    action: () => window.XenForo.getXenForoModal(
                        'auction-process/relocation', {id: auction_id}, 'GET')
                },
                {
                    phrase: ak_au_edit,
                    order: 3,
                    conditions: (editOthersAuctions),
                    action: () => window.XenForo.getXenForoModal(
                        'auction-process/form-edit-title', {id: auction_id}, 'GET')
                },
            ];

            return orderBy(filter(buttonsOption, (b) => {
                return b.conditions
            }), ['order'], ['asc']);
        },
        // Only re-render if the buttonsOption props change
        [auction_id, isdel, status, user_id, watch_user_id, RestoreLots,
            view_all_bids, editOthersAuctions, deleteLots, hidden_sellers, visitor_user_id]
    );

    return (
        <div className="d-flex admin-lot-button">
            {buttonsArr.map((item, key) => (
                <Button
                    data-action
                    onClick={() => item.action()}
                    key={key}
                    className="text-muted"
                    variant="link"
                >
                    {item.phrase}
                </Button>
            ))}
        </div>
    );
};

export default AdminLotButtons;