import {SET_DATA_SIDEBAR_STATS, SET_SHOW_BLOCK_SIDEBAR_STATS} from "../../types";
import {isUndefined} from "lodash/lang";

export const setDataStats = (data) => {
    return (dispatch, getState) => {
        const {sale_lots, dispute_count, for_sale_lots, for_sale_lots24, sale_lots24} = data

        const {
            phrases: {
                ak_au_ph_total_lots_sold,
                ak_au_ph_lots_for_sale,
                ak_au_ph_new_lots_in_24_hours,
                ak_au_ph_lots_sold_in_24_hours,
                ak_au_ph_total_disputes_processed
            }
        } = getState().lots

        const arr = [
            {phrase: ak_au_ph_total_lots_sold, count: isUndefined(data) ? 0 : sale_lots},
            {phrase: ak_au_ph_lots_for_sale, count: isUndefined(data) ? 0 : for_sale_lots},
            {phrase: ak_au_ph_new_lots_in_24_hours, count: isUndefined(data) ? 0 : for_sale_lots24},
            {phrase: ak_au_ph_lots_sold_in_24_hours, count: isUndefined(data) ? 0 : sale_lots24},
            {phrase: ak_au_ph_total_disputes_processed, count: isUndefined(data) ? 0 : dispute_count},
        ]

        dispatch({type: SET_DATA_SIDEBAR_STATS, payload: arr});
    }
}

export const setShowBlockSidebarStats = () => {
    return (dispatch, getState) => {
        const {show} = getState().sidebarStats
        dispatch({type: SET_SHOW_BLOCK_SIDEBAR_STATS, payload: !show})
    }
}