import React from "react";
import {useSelector} from "react-redux";

const ModalErrorBlock = () => {

    const errors = useSelector((state) => {
        return state.modal.errors
    });

    if (errors.length === 0) {
        return null;
    }

    const error = () => {
        return errors.map((item, key) => {
            return (
                <div key={key} className="alert alert-danger my-1" role="alert">
                    {item.phrase}
                </div>
            );
        });
    };

    return (
        <div className="col-12 my-2">
            {error()}
        </div>
    );


};

export default ModalErrorBlock