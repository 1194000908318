import {SET_QUESTION_DATA} from "../../types";

const initialState = {
    modalShow: false,
    message: '',
    auction_id: null,
    attachments: [],
    attachmentParams: {},
    id_question_lot: 0,
    deletingQuestion: false,
    reportQuestion: false,
    parent_question_id: 0,
    delete_type: 0,
    delete_reason: '',
};

export const replyQuestionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_QUESTION_DATA:
            return {...state, ...action.payload};
        default:
            return state
    }
}