import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {onChangeAction, onSubmitForm, toggleModal} from "../../../../../redux/modals/modal-inline-mod-check/actionModal";

const ModalFooter = () => {

    const dispatch = useDispatch();
    const {close, save_changes} = useSelector((state) => {
        return state.lots.phrases
    });

    const onCloseModal = () => {
        dispatch(onChangeAction());
        dispatch(toggleModal(false));
    };

    return (
        <div className="modal-footer">
            <button type="button" onClick={() => onCloseModal()}
                    className="btn btn-secondary btn-sm mx-1">{close}
            </button>
            <button type="button" onClick={() => dispatch(onSubmitForm())} className="btn btn-primary btn-rg btn-sm mx-1">
                {save_changes}
            </button>
        </div>
    );

}

export default ModalFooter