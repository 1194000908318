import BaseApiService from "./base-api-service";

export default class InlineModApiService extends BaseApiService{

    constructor() {
        super();
        this._apiBase = `${this._baseUrl}/auction-inlinemod`;
    }
    setParamsInlineBlock = async (obj) => {
        return await this.getResource(`/api-lots-operations`, obj, 'POST');
    }
}