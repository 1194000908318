import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import FilterTopBar from "./filter-top-bar";
import ArchiveSubscriptionRssBlock from "./archive-follow-rss-block";
import InterestingLots from "./interesting-lots";
import CategoriesNav from "./categories-nav";
import {filter, includes, map} from "lodash/collection";
import {getQueryLinkParams} from "../../helpers/routing-helper";

const TopBar = () => {

    const {loadingBaseParams} = useSelector((state) => {
        return state.lots
    })

    const {user_lots_type} = getQueryLinkParams();

    return useMemo(() => {

        const arr = [
            {
                condition: user_lots_type === 'auction_lots',
                component: <ArchiveSubscriptionRssBlock/>
            },
            {
                condition: user_lots_type === 'auction_lots',
                component: <InterestingLots/>
            },
            {
                condition: includes(['auction_lots', 'archive_lots'], user_lots_type),
                component: <CategoriesNav/>
            },
            {
                condition: includes(['auction_lots', 'archive_lots'], user_lots_type),
                component: <FilterTopBar/>
            },
        ];

        return map(filter(arr, 'condition'), (obj, key) => {
            return (
                <React.Fragment key={key}>
                    {obj.component}
                </React.Fragment>
            )
        })

    }, [user_lots_type, loadingBaseParams])

}
export default TopBar