import React, {createContext, useContext, useMemo, useState} from "react";
import {find, groupBy} from "lodash/collection";
import {reverse, last, head, findIndex} from "lodash/array";
import {isUndefined} from "lodash/lang";
import './modal-create-lot.css'
import {Alert, Dropdown} from "react-bootstrap";
import {getQueryLinkParams} from "../../../helpers/routing-helper";
import CreateBlock from "./create-block";
import {modalCreateLotContext} from "./modal-create-lot";

const mclc = createContext();
export {mclc}
const ModalCreateLotContent = () => {

    const auction_category_id = getQueryLinkParams().auction_category_id
    const [categoryId, setCategory] = useState(auction_category_id);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [blockData, setBlockData] = useState([]);

    const {
        setCurrentCategoryId, error, all_categories, phrases: {
            ak_select_cat, ak_au_LableDiscription_Create_category, ak_au_LableSelectCategory,
            ak_au_LableSelectCategoryFirst
        }
    } = useContext(modalCreateLotContext)

    const getSelectedCategories = () => {
        let allSelectedCategories = [categoryId]
        const getCategories = (category_id) => {
            const {parent_category_id, auction_category_id} = find(all_categories, {auction_category_id: category_id});

            if (auction_category_id !== categoryId) {
                allSelectedCategories = [...allSelectedCategories, auction_category_id];
            }
            if (parent_category_id !== 0) {
                getCategories(parent_category_id)
            }
        }

        if (categoryId) {
            getCategories(categoryId)
        }

        return reverse(allSelectedCategories);
    }


    React.useEffect(() => {
        setCategory(auction_category_id)
        setCurrentCategoryId(auction_category_id)
    }, [auction_category_id])

    React.useEffect(() => {
        setSelectedCategories(getSelectedCategories)
    }, [categoryId, all_categories])


    const getBlockCategories = () => {
        let block = selectedCategories.map((val, key) => {
            if (val === 0) {
                return {parent_category_id: 0, defaultSelect: val}
            }

            const {parent_category_id} = find(all_categories, {auction_category_id: val});
            return {parent_category_id, defaultSelect: val}
        })

        const lastEl = last(selectedCategories)

        if (lastEl !== 0 && !isUndefined(objParentCategories[lastEl])) {
            block = [...block, {parent_category_id: lastEl, defaultSelect: 0}];
        }
        return block
    }

    React.useEffect(() => {
        const block = getBlockCategories()
        setBlockData(block)
    }, [selectedCategories, categoryId])

    const objParentCategories = useMemo(() => groupBy(all_categories, 'parent_category_id'), [all_categories]);

    if (selectedCategories.length === 0 || blockData.length === 0) {
        return null
    }

    const errorBlock = (!error)
        ? null : <Alert className='col-12 no-gutters mb-3' variant={'danger'}>{ak_select_cat}</Alert>

    return (
        <form id='form-create-lot' name='form-create-lot'>
            <div id='create-lot' className="form-row">
                {errorBlock}
                <div className="col-12 no-gutters mb-3">
             <span className="form-text">
                    {ak_au_LableDiscription_Create_category}
                </span>
                </div>
                <mclc.Provider value={{
                    blockData, setBlockData, selectedCategories, setCategory, objParentCategories
                }}>
                    <CreateBlock/>
                </mclc.Provider>
                <div className="col-12 no-gutters">
             <span className="form-text ">
                    {ak_au_LableSelectCategoryFirst}
                </span>
                    <span className="form-text">
                    {ak_au_LableSelectCategory}
                </span>
                </div>
            </div>
        </form>
    )
}

export default ModalCreateLotContent