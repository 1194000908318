import React from 'react'
import {useDispatch, useSelector} from "react-redux";

import ModalHeader from "../modal-header";
import ModalErrorBlock from "../modal-error-block";
import ModalAction from "../modal-actions";
import CategoriesBlock from "../../../../categories-block";
import CopyBlock from "../copy-block";
import RemoveBlock from "../remove-block";
import ModalFooter from "../modal-footer";
import {onChangeCategory} from "../../../../../redux/modals/modal-inline-mod-check/actionModal";
import ModalSetDate from "../../../modal-set-date";
import ModalSetEndTime from "../../../modal-set-end-time";
import ModalTradingDuration from "../../../modal-trading-duration";

const ModalMainView = () => {

    const dispatch = useDispatch();

    const modalState = useSelector((state) => {
        return state.modal
    });

    const lotsState = useSelector((state) => {
        return state.lots
    });

    const {modalShow} = modalState

    if (!modalShow) {
        return null;
    }

    return (
        <div id='modal_lots_action' className='modal d-block show' tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <ModalHeader/>
                    <div className="container">
                        <div className="row align-items-center">
                            <form id="form_action" className="form-horizontal">
                                <div className="form-group align-items-start flex-column">
                                    <ModalErrorBlock/>
                                    <ModalAction/>
                                    <ModalSetDate/>
                                    <ModalSetEndTime/>
                                    <ModalTradingDuration/>
                                    <CategoriesBlock
                                        {...lotsState}
                                        {...modalState}
                                        onChangeCategory={(category_id)=> dispatch(onChangeCategory(category_id))}
                                    />
                                    <CopyBlock/>
                                    <RemoveBlock/>
                                </div>
                            </form>
                        </div>
                    </div>
                    <ModalFooter/>
                </div>
            </div>
        </div>
    );
}


export default ModalMainView;