import {SET_DATA_REMOVE_LOT_FROM_SALE_MODAL} from "../../types";

const initialState = {
    modalShow: false,
    type: 0,
}

export const modalRemoveLotFromSaleReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_DATA_REMOVE_LOT_FROM_SALE_MODAL :
            return {...state, ...action.payload};
        default: return state;
    }

}