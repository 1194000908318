import {SET_DATA_SIDEBAR_STATS, SET_SHOW_BLOCK_SIDEBAR_STATS} from "../../types";

const initialState = {
    statsData: [],
    show: true,
    loading: true
}


export const SideBarStatsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA_SIDEBAR_STATS:
            return {...state, statsData: action.payload}
        case SET_SHOW_BLOCK_SIDEBAR_STATS:
            return {...state, show: action.payload}
        default:
            return state;
    }
}