import React, {useCallback, useContext, useMemo} from 'react';
import './form-payment-lot.css'
import {find} from "lodash/collection";
import {isEmpty, isNull} from "lodash/lang"
import {Icon} from '@iconify/react';
import creditCard from '@iconify/icons-noto/credit-card';
import {useDispatch, useSelector} from "react-redux";
import {getFormattedDate} from "../../../redux/lots/action";
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";
import {LotsContext} from "../../auction-lots-listing/auction-lots-listing";

const FormPaymentLot = () => {

    const dispatch = useDispatch();
    const {lotData:{user_id, payment_id, top_bid, finish_time_for_pay, waitforpay_date}} = useContext(lotContext);
    const {phrases, time_wait_pay} = useContext(LotsContext);
    const users_payment_methods = useSelector(state => state.lots.users_payment_methods);

    const {
        ak_au_you_pay_this_lot_payment_name,
        ak_au_ph_copy_card_number_clipboard,
        ak_au_you_pay_this_lot_must_pay,
        ak_au_card_number,
    } = phrases;

    const copyCard = useCallback(() => {
        const range = document.createRange();
        range.selectNode(document.getElementById('card'));
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
    }, []);

    return useMemo(() => {

        if (isEmpty(users_payment_methods)) {
            return null
        }

        if (!payment_id) {
            return null
        }

        const {
            payment_active,
            payment_info,
            payment_system_name,
            payment_user_data: {bank_card = null}
        } = find(users_payment_methods[user_id], {'payment_id': payment_id});

        if (!payment_active) {
            return null
        }

        const payDate = dispatch(getFormattedDate(parseInt(waitforpay_date) + time_wait_pay))

        let bc = (!isNull(bank_card))
            ? <div className='d-flex align-items-center'>
                <div className='pt-2 mr-1'>
                    <span>{ak_au_card_number}:</span>
                    <span id='card'>{bank_card.match(/.{1,4}/g).join(" ")}</span>
                </div>
                <div>
                        <span className='card-icon'
                              onClick={() => copyCard()}
                              title={ak_au_ph_copy_card_number_clipboard}>
                           <Icon icon={creditCard}/>
                       </span>
                </div>
            </div>
            : null;

        return (
            <div className='row flex-column m-1'>
                <div className='col-12'>
                    <div className="row flex-md-column flex-lg-row ">
                    <span className='mr-1 pb-2 pb-lg-0'>
                        {ak_au_you_pay_this_lot_payment_name.replace(/\{payment_name\}/, payment_system_name)}
                    </span>
                        <span>{payment_info}</span>
                    </div>
                </div>
                {bc}
                <div>
                    {ak_au_you_pay_this_lot_must_pay
                        .replace(/\{auction_top_bid\}/, top_bid)
                        .replace(/\{finish_time_for_pay\}/, payDate)}
                </div>
            </div>
        );
    }, [waitforpay_date, top_bid, finish_time_for_pay, users_payment_methods, payment_id]);


}
export default FormPaymentLot