import {useContext, useMemo} from 'react';
import { some } from 'lodash/collection';
import {LotsContext} from "../../auction-lots-listing";

const useIsLotSelected = (auction_id) => {
    const {changeLots} = useContext(LotsContext);
    return  useMemo(() => some(changeLots, { auction_id }), [changeLots]);
};

export default useIsLotSelected;
