import BaseApiService from "./base-api-service";


export default class ProcessApiService extends BaseApiService{

    constructor() {
        super();
        this._apiBase = `${this._baseUrl}/auction-process`;
    }

    saleRemove = async (obj) => {
        return await this.getResource(`/sale-remove`, obj, 'POST');
    }

    setAnswer = async (obj) => {
        return await this.getResource(`/expire`, obj, 'POST');
    }

    setHiddenLots = async (obj) => {
        return await this.getResource(`/set-hidden-lots`, obj, 'POST');
    }

    updateCountUsersWatchingLot = async (obj) => {
        return await this.getResource('/update-count-users-watching-lot', obj)
    }

    viewLotSale = async (obj) => {
        return await this.getResource('/view-lot-sale', obj)
    }

    getDataLotQuantity = async (obj) => {
        return await this.getResource('/edit-lot-quantity', obj)
    }

    setNewQuantity = async (obj) => {
        return await this.getResource('/edit-lot-quantity', obj, 'POST');
    }

    extendPeriodReceipt = async (obj) => {
        return await this.getResource('/form-updatedate', obj);
    }

    /**
     * @param {{auction_id: auction_id}|{}} obj
     */
    followAuctionLot = async (obj) => {
        return await this.getResource('/follow-auction-lot', obj);
    }

    setFlagManagementInActiveLot = async (obj) => {
        return await this.getResource('/Expire_Flag', obj, 'POST');
    }

    saveUserSortSettings = async (obj) => {
        return await this.getResource(`/save-user-sort-settings`, obj, 'POST');
    }

}