import {SET_DATA_MODAL_CREATE_LOT} from "../../types";

const initialState = {
    showModal: false,

};

export const modalCreateLotReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA_MODAL_CREATE_LOT:
            return {...state, ...action.payload};
            default:
                return state;
    }
}