import {SET_DATA_EDIT_PRICE, SHOW_MODAL_EDIT_PRICE} from "../../types";

const initialState = {
    modalShow: false,
    auction_id: null,
    starting_bid: 0,
    buyout_price:0,
    data: {}
};

export const modalEditPriceReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA_EDIT_PRICE:
            return {...state, ...action.payload};
        case SHOW_MODAL_EDIT_PRICE:
            return {...state, modalShow: action.payload};
        default:
            return state
    }
}