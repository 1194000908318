import ReibertApiService from "../../services/reibert-api-service";
import {SET_DEFAULT_PARAMS, SET_SIDEBAR_DEFAULT_LOADING_DATA} from "../types";
const reibertApiService = new ReibertApiService();

export function getSideBarDefaultParams() {
    return async (dispatch) => {

        reibertApiService.getDefaultParamsSideBar().then(
            json => {
                const {phrases} = json
                dispatch({type: SET_DEFAULT_PARAMS, payload: {phrases}});
                dispatch({type: SET_SIDEBAR_DEFAULT_LOADING_DATA, payload: false})
            }
        )
    }
}