import React from "react";
import {Dropdown, DropdownButton} from "react-bootstrap";
import {useSelector} from "react-redux";
import ArchiveItemsBlock from "../../../archive-items-block";

const ArchiveBlock = () => {
    const {ak_au_ph_archive} = useSelector((state) => {
        return state.lots.phrases
    });

    return (
        <div className="ml-1">
            <Dropdown>
                <DropdownButton
                    size='sm'
                    variant="info btn-rg"
                    id="dropdown-archive"
                    title={ak_au_ph_archive}>
                    <ArchiveItemsBlock/>
                </DropdownButton>
            </Dropdown>
        </div>
    )
}

export default ArchiveBlock