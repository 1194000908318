import React, {memo, useContext} from 'react'
import LotThumbnail from "../../repeating-components/lot-thumbnail";
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";
import GlobalHelper from "../../../helpers/global-helper";
import useImageGalleryFn from "../../modals/modal-image-gallery/useImageGalleryFn";

const LotListThumbnail = memo(() => {

    const {lotData: {title, primary_image}} = useContext(lotContext);
    const {showModalImg} = useImageGalleryFn();

    const showImage = () => {
        showModalImg([{...primary_image}])
    }

    const lotThumbnailProps = React.useMemo(() => ({
        title,
        imgUrl: GlobalHelper.setImg(primary_image, true),
        setShow: showImage
    }), [title, primary_image, showImage]);

    return (
        <LotThumbnail {...lotThumbnailProps}/>
    )

})
export default LotListThumbnail