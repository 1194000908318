import React, {useContext} from 'react'
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";

import {deleteChangeLot, setChangeLot, toggleModal} from "../../../redux/modals/modal-inline-mod-check/actionModal";
import {useDispatch} from "react-redux";
import useIsLotSelected from "../../auction-lots-listing/components/lots-list-item/useIsLotSelected";
const CheckBox = React.memo(() => {
    const dispatch = useDispatch();
    const {lotData:{auction_id}} = useContext(lotContext);

    let selected = useIsLotSelected(auction_id);

    const onToggleLot = (auction_id) => {
        const newCheckState = !selected;

        if (newCheckState) {
            dispatch(setChangeLot(auction_id));
            dispatch(toggleModal(newCheckState));
        } else {
            dispatch(deleteChangeLot(auction_id));
        }
    };

    return (
        <input checked={selected}
               className="custom-control-input"
               id={`lot_${auction_id}_title`}
               type="checkbox"
               value={auction_id}
               onChange={() => onToggleLot(auction_id)}
               data-action
        />
    );
})
export default CheckBox