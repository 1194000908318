import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setParamsSetDateModal} from "../../../redux/modals/modal-set-date/actionSetDate";
import {onSubmitForm} from "../../../redux/modals/modal-inline-mod-check/actionModal";
import ModalContentSetDate from "./modal-content-set-date";

const ModalSetDate = () => {

    const dispatch = useDispatch();

    const {handler_type} = useSelector((state) => {
        return state.modal
    });

    const {newChosenDate, sendData} = useSelector(state => {
        return state.modalSetDate
    });

    useEffect(() => {
        if (handler_type === 17) {
            dispatch(setParamsSetDateModal({modalShow: true}));
        }
    }, [handler_type]);

    if (handler_type !== 17) {
        return null;
    }

    const sendForm = () => {
        if (newChosenDate !== null) {
            dispatch(setParamsSetDateModal({sendData: !sendData}));
            dispatch(onSubmitForm());
        }
    }

    return <ModalContentSetDate sendForm={sendForm}/>

}

export default ModalSetDate