import {GET_DFAULT_LOTS_LIST_PARAMS, SET_SIDEBAR_BLOCK_DATA, SET_SIDEBAR_LOADING_LOTS_IN_SIDEBAR, SET_SIDEBAR_LOTS_COUNT} from "../../types";
import ReibertApiService from "../../../services/reibert-api-service";
import {getQueryLinkParams} from "../../../helpers/routing-helper";
import {clearChangeLot} from "../../modals/modal-inline-mod-check/actionModal";

const reibertApiService = new ReibertApiService();

export function getLotsCountInSidebar() {
    return async (dispatch) => {
       reibertApiService.getCountLotsInSidebar().then(json => {
           const {sidebar_counts} = json;
           dispatch({type: SET_SIDEBAR_LOTS_COUNT, payload: sidebar_counts})
           dispatch(updateParamsUserSidebar(true));
        });
    }
}

export function setCountLotsInSidebar(data) {
    return async (dispatch) => {
        dispatch({type: SET_SIDEBAR_BLOCK_DATA, payload: data})
        dispatch(updateParamsUserSidebar(false));
    }
}


export function updateParamsUserSidebar(type) {
    return dispatch => {
        dispatch({type: SET_SIDEBAR_LOADING_LOTS_IN_SIDEBAR, payload: type})
    }
}

export function setShowBlock(block, type) {
    return (dispatch, getState) => {
        const state = getState();

        const {blockData} = (type === SET_SIDEBAR_BLOCK_DATA) ? state.userSidebar : state.filterSidebar

        const newArr = blockData.map(item => {
            if (item.block === block) {
                item.show = !item.show;
            }
            return item
        });

        dispatch({type: type, payload: newArr})
    }
}

export function actionLink(val) {
    const {user_lots_type, type, order, direction} = val

    const data = {user_lots_type}
    const dataType = {type, order, direction}

    return (dispatch, getState) => {
        const {visitor_user_id, user_id} = getState().lots;
        const {user_lots_type, type} = getQueryLinkParams()
        if (user_lots_type !== data.user_lots_type || type !== dataType.type || visitor_user_id !== user_id) {
            const defaultData = {loading: true, user_id: visitor_user_id}
            dispatch({type: GET_DFAULT_LOTS_LIST_PARAMS, payload: defaultData});
            dispatch(clearChangeLot());
        }
        return true;
    }
}



