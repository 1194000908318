import React, { useCallback, useContext, useMemo } from 'react';
import './category-line-block.css';
import CategoryLinkComponent from "../../repeating-components/wrapper-components/category-link-component";
import { isObject } from "lodash/lang";
import { lotContext } from "../../auction-lots-listing/components/lots-list-item/lots-list-item";
import {find} from "lodash/collection";
import { LotsContext } from "../../auction-lots-listing/auction-lots-listing";


const CategoryLineBlock = () => {
    const { lotData: { auction_category_id } } = useContext(lotContext);
    const {auction_categories} = useContext(LotsContext)

    const createLotCategories = useCallback((category_id) => {
        const categories = [];
        let currentCategory = find(auction_categories, { auction_category_id: category_id });

        while (isObject(currentCategory)) {
            categories.push(currentCategory);
            if (parseInt(currentCategory.parent_category_id, 10) === 0) {
                break;
            }
            currentCategory = find(auction_categories, { auction_category_id: currentCategory.parent_category_id });
        }

        return categories.reverse();
    }, [auction_categories]);

    const categories = useMemo(() => createLotCategories(auction_category_id), [auction_category_id, createLotCategories]);

    if (!categories?.length) {
        return null;
    }

    return (
        <div className="category-line-link-block muted">
            {categories.map((item) => (
                <span key={item.auction_category_id} className="item-category-link">
                    <CategoryLinkComponent item={item} className=''>
                        {item.category_title}
                    </CategoryLinkComponent>
                </span>
            ))}
        </div>
    );
};

export default CategoryLineBlock;
