import {SET_INTERESTING_LOTS_DATA} from "../../types";

const initialState = {
    interestingLots: {}
}

export const interestingLotsReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_INTERESTING_LOTS_DATA:
            return {...state, interestingLots: action.payload}
        default:
            return state
    }
}