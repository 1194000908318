import {SET_PARAMS_SORTING_SETTINGS, UPDATE_LOTS_DATA} from "../../types";
import {findIndex} from "lodash/array";
import ProcessApiService from "../../../services/process-api-service";
import {updateParamsUserSidebar} from "../../sidebars/user-sidebar/actionUserSideBar";

export function setParamsSortingSettings(data) {
    return dispatch => {
        dispatch({type: SET_PARAMS_SORTING_SETTINGS, payload: data})
    }
}

export function updateUserSortingSetting(sortObj) {
    return (dispatch, getState) => {

        const {user_sort_data} = getState().lots
        const {user_lots_type, type, direction, sort_order, show_img_lots_list} = sortObj

        const el = findIndex(user_sort_data, {user_lots_type, type});
        const arr = [...user_sort_data.slice(0, el), ...user_sort_data.slice(el + 1)];
        const newArr = [...arr, {user_lots_type, type, direction, sort_order, show_img_lots_list}]

        const payload = {user_sort_data: newArr};

        dispatch({type: UPDATE_LOTS_DATA, payload});

        const {saveUserSortSettings} = new ProcessApiService();

        saveUserSortSettings(sortObj).then((json) => {
            const {_redirectStatus} = json
            if (_redirectStatus === 'ok') {
                dispatch(updateParamsUserSidebar(true));
            }
        });
    }
}

