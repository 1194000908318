import {SET_SIDEBAR_BLOCK_DATA, SET_SIDEBAR_LOADING_LOTS_IN_SIDEBAR, SET_SIDEBAR_LOTS_COUNT} from "../../types";

const initialState = {
    loading: true,
    sidebar_counts: {       
        run_disputs: 0,
        other_disputs: 0,
        user_disputs: 0,
        your_purchases_bids: 0,
        your_purchases_activebids: 0,
        your_purchases_watch_lots: 0,
        your_purchases_waitforpay: 0,
        your_purchases_send: 0,
        your_purchases_take: 0,
        your_purchases_expired: 0,
        your_purchases_no_expired: 0,
        your_auctions_sales: 0,
        your_auctions_awaiting_payment: 0,
        your_auctions_awaiting_shipment: 0,
        your_auctions_awaiting_confirmation: 0,
        your_auctions_no_pay: 0,
        your_auctions_sale: 0,
        your_auctions_nosale: 0,
        your_auctions_finalized: 0,
        your_auctions_inactive_copy: 0,
        your_auctions_vacation: 0,
        moder: {
            moder_awaiting_payment: 0,
            moder_awaiting_shipment: 0,
            moder_awaiting_confirmation: 0,
            moder_no_pay: 0,
            open_disputs: 0,
            close_disputs: 0
        },
    },
    blockData: []
}

export const userSideBarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SIDEBAR_BLOCK_DATA:
            return {...state, blockData: action.payload}
        case SET_SIDEBAR_LOADING_LOTS_IN_SIDEBAR:
            return {...state, loading: action.payload}
        case SET_SIDEBAR_LOTS_COUNT:
            return {...state, sidebar_counts: action.payload}
        default:
            return state;
    }
}