import {useSelector} from "react-redux";
import {getQueryLinkParams} from "../../../helpers/routing-helper";
import {useSearchParams} from "react-router-dom";


const useTopBarButtonFn = () => {

    const [searchParams] = useSearchParams();

    const obj = useSelector((state) => {
        const {lots} = state;

        return {
            all_categories: lots.all_categories,
            visitor_user_id: lots.visitor_user_id,
            phrases: lots.phrases,
            user_prefixes: lots.user_prefixes,
            prefix_id: searchParams.get('prefix_id'),
            auction_category_id: getQueryLinkParams().auction_category_id,
        };
    });

    return {...obj};

}

export default useTopBarButtonFn