import {find} from "lodash/collection";
import {Dropdown, DropdownButton} from "react-bootstrap";
import React from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getLinkData} from "../../../redux/lots/action";

const SortButtons = (props) => {

    const {order, direction, sortingData} = props;
    const {defaultDirection, orderSelectTitle} = find(sortingData, {order});

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const toggleSortButton = (e, data) => {
        e.preventDefault();
        const {direction, order} = data
        _setLink({direction, order})
    }

    const toggleDirectionButton = (e, direction) => {
        e.preventDefault();
        _setLink({direction})
    }

    const _setLink = (data) => {
        const queryLink = dispatch(getLinkData({...data, page: 1}));
        navigate(`?${queryLink}`);
    }

    const createSortButtons = () => {
        return sortingData.map((data) => {
            const {order, orderSelectTitle} = data
            return (
                <Dropdown.Item
                    key={order}
                    as="button"
                    onClick={(e) => toggleSortButton(e, data)}>
                    {orderSelectTitle}
                </Dropdown.Item>
            );
        });
    }

    const createDirectionButton = () => {
        return Object.keys(defaultDirection).map(key => {

            return (
                <Dropdown.Item
                    key={key} as="button"
                    onClick={(e) => toggleDirectionButton(e, key)}>
                    {defaultDirection[key]}
                </Dropdown.Item>
            )
        });
    }

    const dropdownButton = (title, buttons) => {

        return (
            <div className="shop-select m-1">
                <DropdownButton
                    size='sm'
                    variant="info btn-rg"
                    id="dropdown-item-order-button"
                    title={title}>
                    {buttons}
                </DropdownButton>
            </div>
        );
    };

    return (
        <>
            {dropdownButton(orderSelectTitle, createSortButtons())}
            {dropdownButton(defaultDirection[direction], createDirectionButton())}
        </>
    )
}

export default SortButtons