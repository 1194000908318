import React, {useContext} from 'react'
import {useSelector} from "react-redux";
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";

const ReasonDeleteLot = (props) => {
    const {isdel, del_reason, clazz='text-danger small pb-1'} = props

    const {
        phrases: {ak_au_ph_lot_deleted_reason}
    } = useSelector(state => state.lots);

    return (isdel)
        ? <span className={clazz}>{`${ak_au_ph_lot_deleted_reason} ${del_reason}`}</span>
        : null

}
export default ReasonDeleteLot