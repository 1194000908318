import React, {useMemo, useState} from 'react'
import moment from "moment/moment";
import {Dropdown, DropdownButton} from "react-bootstrap";
import WithSuspense from "../../repeating-components/wrapper-components/with-suspense";
import {setParamsSetEndTimeModal} from "../../../redux/modals/modal-set-end-time/actionSetEndTime";
import {useDispatch, useSelector} from "react-redux";
import './modal-set-end-time.css';

const ModalBlackBackground = React.lazy(() => import('../../repeating-components/wrapper-components/modal-black-background'));

const ModalContentSetEndTime = (props) => {
    const dispatch = useDispatch();

    const {sendForm} = props;

    const {
        phrases: {
            ak_au_ph_edit_end_time,
            ak_au_edit_time_only_promoted_lot,
            ak_au_not_chosen_an_end_time,
            save,
        }
    } = useSelector(state => {
        return state.lots
    });

    const [placeholder, setPlaceholder] = useState(ak_au_not_chosen_an_end_time);

    const {modalShow} = useSelector(state => {
        return state.modalSetEndTime
    });

    const setParams = (data) => {
        dispatch(setParamsSetEndTimeModal(data));
    }

    const handleShow = () => {
        setParams({modalShow: !modalShow});
    }

    const onChangeTime = (val) => {
        setPlaceholder(val)
        setParams({newChosenTime: val});
    }

    const getTime = useMemo(() => {
        let today = new Date();

        let time = [];

        let i = 0;
        while (i <= 23) {
            let m = 0
            while (m <= 55) {
                let t = today.setHours(i, m, 0, 0);

                let tt = moment(t).format("HH:mm");

                time = [...time, tt];
                m += 5
            }
            i++;
        }

        return time.map((value, index) => {
            return (
                <Dropdown.Item
                    className=""
                    key={index}
                    as="button"
                    active={false}
                    onClick={() => onChangeTime(value)}
                >
                    <span>{value}</span>
                </Dropdown.Item>
            )
        })
    }, [])


    const modalContent = useMemo(() => {
        return (
            <div className='row justify-content-center'>
                <div className="row flex-column align-items-center">
                    <div className="alert alert-danger" role="alert">
                        {ak_au_edit_time_only_promoted_lot}
                    </div>
                    <DropdownButton
                        className={'set-end-time'}
                        variant="outline-light"
                        title={placeholder}
                    >
                        {getTime}
                    </DropdownButton>
                </div>
            </div>
        )
    },[placeholder, getTime])

    return (
        <WithSuspense>
            <ModalBlackBackground
                setShow={handleShow}
                show={modalShow}
                sendForm={sendForm}
                phraseTitle={ak_au_ph_edit_end_time}
                phraseSubmit={save}
            >
                {modalContent}
            </ModalBlackBackground>
        </WithSuspense>
    )

}
export default ModalContentSetEndTime