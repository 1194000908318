import React, {useMemo} from 'react';
import {Dropdown} from "react-bootstrap";
import {getArchiveSwichData} from "../../redux/archive/actionArchive";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {find} from "lodash/collection";
import GlobalHelper from "../../helpers/global-helper";
import {getQueryLinkParams} from "../../helpers/routing-helper";

const ArchiveItemsBlock = ({itemType = 'dropdown'}) => {
    const dispatch = useDispatch();

    const {auction_category_id, year} = getQueryLinkParams()
    const data = dispatch(getArchiveSwichData(year));

    const {all_categories} = useSelector((state) => {
        return state.lots
    });

    const getLink = (link) => {

        if (auction_category_id !== 0) {
            const category = find(all_categories, {auction_category_id});
            return `${link}${GlobalHelper.getCategoryVariableReference(category)}`
        }

        return link
    }

    return useMemo(() => {
        return data.map((val, key) => {
            const {link, title} = val

            if (itemType === 'link') {
                return (
                    <li key={key} className="nav-item">
                        <Link className='nav-link py-1'
                              to={{
                                  pathname: getLink(link),
                              }}>
                            {title}
                        </Link>
                    </li>
                )
            }
            return (
                <Dropdown.Item key={key} as='button'>
                    <Link to={{
                        pathname: getLink(link),
                    }}>{title}</Link>
                </Dropdown.Item>
            )
        })
    }, [data, itemType])
}

export default ArchiveItemsBlock


