import {SET_DISPUT_DATA} from "../types";

const initialState = {
    message: '',
    attachments: [],
    attachmentParams: {},
    disput_id: null,
    id_message: null,
    reply_disput_id: null,

    deletingQuestion: false,
    reportQuestion: false,

    modalShow: false,
    delete_type: 0,
    delete_reason: '',
    disput_state: 'open',

    showModal: false, // ModalUsersDisputeBlocking
    blockingType: 'no_block',

    resolutionState: 'denied', // or agreed

    showModalResolution: false,
    status: '',
    takeCommission: 0,
    creatorPenaltyObj: {points: 0, period: 0, time_type: 0, message: ''},
    respondentPenaltyObj: {points: 0, period: 0, time_type: 0, message: ''},

    moderators: [],
    showModalDelegation: false,
    appointedModerator: 0,

    showModalDisputAdd: false,
    disput_respondent: null,
    auction_id: null,
};

export const disputReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DISPUT_DATA:
            return {...state, ...action.payload};
        default:
            return state
    }
}