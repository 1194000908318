import React, {createContext, useCallback, useState} from "react";
import {deleteSessionStorageData} from "../../../redux/crud-lot/actionCrudLot";
import {find} from "lodash/collection";
import GlobalHelper from "../../../helpers/global-helper";
import {useDispatch, useSelector} from "react-redux";
import ModalCreateLotContent from "./modal-create-lot-content";
import ModalBlackBacground from '../../repeating-components/wrapper-components/modal-black-background';
import {useNavigate} from "react-router-dom";
import {onToggleModal} from "../../../redux/modals/modal-create-lot/actionModalCreateLot";

const modalCreateLotContext = createContext();
export {modalCreateLotContext};
const ModalCreateLot = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const modalData = useSelector(state => {
        const {lots, modalCreateLot} = state;

        return {
            showModal: modalCreateLot.showModal,
            all_categories: lots.all_categories,
            phrases: lots.phrases
        }
    });

    const {phrases, showModal, all_categories} = modalData;
    const {ak_select_cat, ak_au_change} = phrases;

    const handleShow = () => {
        dispatch(onToggleModal(!showModal))
    }

    const [categoryId, setCategoryId] = useState(0)
    const [error, setError] = useState(false)

    const setCurrentCategoryId = (categoryId) => {
        setCategoryId(categoryId);
        setError(false);
    }

    const categoryLink = useCallback(() => {
        if (!categoryId) {
            setError(true);
            return false;
        }
        const item = find(all_categories, {auction_category_id: categoryId});
        return GlobalHelper.getCategoryVariableReference(item);
    }, [categoryId]);

    const sendForm = () => {

        const link = categoryLink();

        if (!link) {
            return false;
        }
        handleShow();
        dispatch(deleteSessionStorageData())
        navigate(`/auction-process/create/${link}`);
    }

    return (
        <ModalBlackBacground
            setShow={handleShow}
            show={showModal}
            sendForm={sendForm}
            phraseTitle={ak_select_cat}
            phraseSubmit={ak_au_change}
        >
            <modalCreateLotContext.Provider value={{...modalData, setCurrentCategoryId, error}}>
                <ModalCreateLotContent/>
            </modalCreateLotContext.Provider>
        </ModalBlackBacground>
    )
}
export default ModalCreateLot