import {SET_PENALTY_FOR_MESSAGE_DATA} from "../../types";

const initialState = {
    modalShow: false,
    auction_id: null,
    penalty_user_id: null,
    message: '',
    id_question_lot: 0,
    penalty_type: 0,
    penalty_count: 0,
    period: 0,
    period_type:0,
    phrases: {},
    warnings: [],
}

export const penaltyForMessageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PENALTY_FOR_MESSAGE_DATA:
            return {...state, ...action.payload};
        default:
            return state
    }
}