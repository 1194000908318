import React, {useMemo} from 'react';
import {Pagination} from "react-bootstrap";
import usePageNavFn from "./usePageNavFn";

const PaginationBlock = (props) => {

    const {onClickPrevPages, onClickNextPages, phrases, showFrom, showTo, step, firstPage, lastPage, activePage} = usePageNavFn()
    const {setActivePage} = props

    return useMemo(() => {

        let items = [];

        if (firstPage + step <= showFrom && firstPage !== lastPage && lastPage !== step) {
            items.push(
                <Pagination.First key={'first_button'} onClick={() => setActivePage(firstPage)}/>,
                <Pagination.Prev key={'prev_button'} onClick={() => setActivePage(activePage - 1)}/>
            );
        }

        items.push(
            <Pagination.Item key={firstPage} onClick={() => setActivePage(firstPage)}
                             active={firstPage === activePage}>
                {firstPage}
            </Pagination.Item>
        );

        if (firstPage + step <= showFrom && firstPage !== lastPage && lastPage !== step) {

            items.push(
                <Pagination.Ellipsis key={`ellipsis_first}`} onClick={() => onClickPrevPages()}/>
            );
        }

        if (lastPage !== 1) {
            for (let number = showFrom; number <= showTo; number++) {
                if (number !== lastPage && number > firstPage) {
                    items.push(
                        <Pagination.Item key={number} onClick={() => setActivePage(number)}
                                         active={number === activePage}>
                            {number}
                        </Pagination.Item>,
                    );
                }
            }
        }

        if (showTo <= (lastPage - step) && firstPage !== lastPage && lastPage !== step) {
            items.push(
                <Pagination.Ellipsis key={`ellipsis_last}`}
                                     onClick={() => onClickNextPages()}/>
            )
        }
        if (lastPage !== 1) {
            items.push(
                <Pagination.Item key={lastPage} onClick={() => setActivePage(lastPage)}
                                 active={lastPage === activePage}>
                    {lastPage}
                </Pagination.Item>
            );
        }
        if (showTo <= (lastPage - step) && firstPage !== lastPage && lastPage !== step) {
            items.push(
                <Pagination.Next key={'next_button'} onClick={() => setActivePage(activePage + 1)}/>,
                <Pagination.Last key={'last_button'} onClick={() => setActivePage(lastPage)}/>
            );
        }


        return (
            <div className="col-lg-12 my-2">
                <div className="d-flex flex-wrap">
                    <p className="p-1 mx-2 pagination-count-page">{phrases.page_x_of_y
                        .replace(/\{current\}/, activePage)
                        .replace(/\{total\}/, lastPage)}</p>
                    <Pagination className='flex-wrap' size={'sm'}>
                        {items.map((item, key) => (
                            <React.Fragment key={key}>
                                {item}
                            </React.Fragment>
                        ))}
                    </Pagination>
                </div>
            </div>
        )
    }, [activePage, lastPage, showFrom, showTo])

}
export default PaginationBlock