import {SET_DATA_ONLINE_USERS, SET_SHOW_BLOCK_ONLINE_USERS} from "../../types";

const initialState = {
    show: true,
    data: {}
}
export const onlineUsersSidebarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SHOW_BLOCK_ONLINE_USERS:
            return {...state, show: action.payload}
        case SET_DATA_ONLINE_USERS:
            return {...state, data: action.payload}
        default :
            return state
    }
}