import React, {useEffect, useMemo, useState} from 'react'
import {useSelector} from "react-redux";

const RemoveBlockContent = (props) => {

    const {
        showBlock = true,
        hardDeleteBlock = true,
        actionRemove = (type, textReasons) => {
            console.log(`type: ${type}, reason: ${textReasons}`)
        }
    } = props

    const {
        phrases: {
            deletion_type,
            remove_from_public_view,
            item_will_remain_viewable_by_moderators_and_may_be_restored_at_later,
            selecting_this_option_will_permanently_and_irreversibly_delete_item,
            permanently_delete,
            reason
        }
    } = useSelector((state) => {
        return state.lots
    });


    const [textReasons, setTextReasons] = useState('')
    const [type, setType] = useState(null)
    const changeRemove = (type) => {
        if (type === 1) {
            setTextReasons('')
        }
        setType(type);
    }

    useEffect(() => {
        actionRemove(type, textReasons)
    }, [type, textReasons])


    const removeFromPublicView = useMemo(() => {
        if (showBlock) {
            return (
                <div className="custom-control custom-radio mb-3">
                    <div className="d-flex py-1">
                        <input type="radio" className="custom-control-input"
                               id="delete_type_one"
                               value={0}
                               name="hard_delete"
                               onChange={(e) => changeRemove(parseInt(e.target.value))}
                        />
                        <label className="custom-control-label" htmlFor="delete_type_one">
                            {remove_from_public_view}
                        </label>
                    </div>

                    <div className='d-flex flex-column py-1'>
                        <input className="form-control-sm"
                               id='delete_reason_type_0'
                               type="text" name="reason"
                               onChange={(e) => setTextReasons(e.target.value)}
                               placeholder={`${reason}...`}/>
                        <div className="text-muted py-1">
                            {item_will_remain_viewable_by_moderators_and_may_be_restored_at_later}
                        </div>
                    </div>
                </div>
            )
        }
        return null;

    }, [showBlock])

    const hardRemoveForm = useMemo(() => {
        if (hardDeleteBlock){
            return (
                <div className="custom-control custom-radio mb-3">
                    <div className="d-flex py-1">
                        <input type="radio"
                               className="custom-control-input"
                               value={1}
                               name="hard_delete"
                               id="delete_type_two"
                               onChange={(e) => changeRemove(parseInt(e.target.value))}
                        />
                        <label className="custom-control-label" htmlFor="delete_type_two">
                            {permanently_delete}
                        </label>
                    </div>

                    <div className='d-flex flex-column'>
                        <input className="form-control-sm d-none"
                               id='delete_reason_type_1'
                               type="text"
                               name="reason"
                               placeholder={`${reason}...`}/>
                        <div className="text-muted">
                            {selecting_this_option_will_permanently_and_irreversibly_delete_item}
                        </div>
                    </div>
                </div>
            )
        }

        return null
    }, [hardDeleteBlock])


    return (
        <div className="container">
            <div className="row">
                <div className="col-12 my-2">
                    <h6>{deletion_type}</h6>

                    {removeFromPublicView}
                    {hardRemoveForm}

                </div>
            </div>
        </div>
    );
}
export default RemoveBlockContent