import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {isUndefined} from "lodash/lang";
// import {deleteSessionStorageData} from "../../../../redux/crud-lot/actionCrudLot";
import {getQueryLinkParams} from "../../../../helpers/routing-helper";
import {onToggleModal} from "../../../../redux/modals/modal-create-lot/actionModalCreateLot";


const ButtonCreateLot = () => {
    const dispatch = useDispatch();

    const {auction_category_id, createAuctions, credits, ak_au_for_create_auction, create_auction} = useSelector((state) => {
        const {visitor, phrases} = state.lots

        return {
            ak_au_for_create_auction: phrases.ak_au_for_create_auction,
            create_auction: phrases.create_auction,
            createAuctions: visitor.permissions.auctions.createAuctions,
            credits: visitor.credits,
            auction_category_id: getQueryLinkParams().auction_category_id
        }
    });


    // useEffect(() => {
    //     if (Number(credits) >= 0) {
    //         const createAuctionLink = document.querySelector('a.create_auction');
    //
    //         if (!isNull(createAuctionLink)) {
    //             createAuctionLink.addEventListener('click', (event) => {
    //                 dispatch(deleteSessionStorageData())
    //             });
    //
    //         }
    //     }
    //
    // }, [credits])
    const handleShow = () => {
        dispatch(onToggleModal(true))
    }

    const can_create_new_lot = parseInt(document.getElementById('can_create_new_lot').value);

    return useMemo(() => {

        if (!can_create_new_lot || !createAuctions || isUndefined(credits)) {
            return null
        }

        if (Number(credits) < 0) {
            return (
                <a className="btn btn-danger btn-rr btn-sm callToAction OverlayTrigger"
                   href='/credits' role="button">{ak_au_for_create_auction}</a>
            )
        }

        if (Number(credits) >= 0) {

            let link = 'auction-process/newCreate';

            if (auction_category_id !== 0) {
                link += `?category_id=${auction_category_id}`
            }

            return (
                <a className="btn btn-danger btn-rr btn-sm"
                   href={link}
                   onClick={(e) => {
                       e.preventDefault();
                       handleShow()
                   }}
                   role="button">{create_auction}</a>
            )
        }

    }, [credits, can_create_new_lot, createAuctions, auction_category_id]);

}
export default ButtonCreateLot