import React, {memo} from 'react'
import './editor.css'
import {useMediaQuery} from "react-responsive";
import AuctionCkEditor from "./auction-ck-editor/auction-ck-editor";

const Editor = memo((props) => {

    const {title = ''} = props
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 991px)'});
    const obj = {...props, isTabletOrMobile}

    return (
        <div className="form-group">
            <label htmlFor="message">{title}</label>
            <AuctionCkEditor {...obj}/>
        </div>
    )

});
export default Editor