import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setParamsSetEndTimeModal} from "../../../redux/modals/modal-set-end-time/actionSetEndTime";
import {onSubmitForm} from "../../../redux/modals/modal-inline-mod-check/actionModal";
import ModalContentSetEndTime from "./modal-content-set-end-time";

const ModalSetEndTime = () => {

    const dispatch = useDispatch();

    const {handler_type} = useSelector((state) => {
        return state.modal
    });

    const {modalShow, newChosenTime, sendTime} = useSelector(state => {
        return state.modalSetEndTime
    });

    useEffect(() => {
        if (handler_type === 23) {
            dispatch(setParamsSetEndTimeModal({modalShow: !modalShow}));
        }
    }, [handler_type]);

    const sendForm = () => {
        if (newChosenTime !== null) {
            dispatch(setParamsSetEndTimeModal({sendTime: !sendTime}));
            dispatch(onSubmitForm());
        }
    }

    if (handler_type !== 23) {
        return null;
    }

    return <ModalContentSetEndTime sendForm={sendForm}/>
}

export default ModalSetEndTime