import React, {memo} from "react";
import ArchiveBlock from "./archive-block";
import RssButton from "./rss-button";
import ButtonCreateLot from './button-create-lot';
import useTopBarButtonFn from "./useTopBarButtonFn";
import SubscriptionCategory from "./subscription-category";
import SubscriptionCategoryPrefix from "./subscription-category-prefix";

const MemoButtonCreateLot = memo(ButtonCreateLot);
const MemoArchiveBlock = memo(ArchiveBlock);
const MemoRssButton = memo(RssButton);
const MemoFollowCategory = memo(SubscriptionCategory);
// const MemoFollowCategoryPrefix = memo(SubscriptionCategoryPrefix);

const ArchiveSubscriptionRssBlock = () => {
    const { visitor_user_id, prefix_id, auction_category_id } = useTopBarButtonFn();

    return (
        <div className="row mb-1">
            <div className="col-12">
                <div className="section-main">
                    <div className="m-2">
                        <div className="d-flex justify-content-center flex-wrap-reverse">
                            <div className="mr-sm-auto">
                                <MemoButtonCreateLot/>
                            </div>
                            <div className="d-flex mb-2 mb-sm-0">
                                <MemoArchiveBlock />
                                {visitor_user_id && auction_category_id ? <MemoFollowCategory/> : null}
                                {visitor_user_id && prefix_id ? <SubscriptionCategoryPrefix/> : null}
                                <MemoRssButton/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ArchiveSubscriptionRssBlock;
