import React, {memo} from 'react';
import './spinner.css';

const Spinner = () => {

    return (
        <div className="loadingio-spinner-dual-ball-ac0bevtdmit">
            <div className="ldio-iwpoaph9ugi">
                <div></div>
                <div></div>
            </div>
        </div>
    )
};

export default memo(Spinner);



