import BaseApiService from "./base-api-service";

export default class LotPageApiService extends BaseApiService {
    constructor() {
        super();
        this._apiBase = `${this._baseUrl}/lot-page`;
    }

    getLotData = async (obj) => {
        return await this.getResource(`/get-lot-data`, obj);
    }

    saveLotDetails = async (obj) => {
        return await this.getResource(`/save-lot-details`, obj, 'POST');
    }

    getLotLogs = async (obj) => {
        return await this.getResource(`/get-lot-logs`, obj);
    }

    getDataEditPrice = async (obj) => {
        return await this.getResource(`/get-data-edit-price`, obj);
    }

    setDataEditPrice = async (obj) => {
        return await this.getResource(`/set-data-edit-price`, obj, 'POST');
    }

    saveAdditionalInfo = async (obj) => {
        return await this.getResource(`/save-additional-info`, obj, 'POST');
    }
    deleteAdditionalInfo = async (obj) => {
        return await this.getResource(`/delete-additional-info`, obj, 'POST');
    }

    setAttachmentAdditionalInfo = async (formData) => {
        return await this.getResource(`/set-attachment-additional-info`, formData, 'POST');
    }

    setAttachmentDisputMessage = async (formData) => {
        return await this.getResource(`/set-attachment-disput-message`, formData, 'POST');
    }
    setQuestionAttachment = async (formData) => {
        return await this.getResource(`/set-question-attachment`, formData, 'POST');
    }

    setStatusNotSold = async (obj) => {
        return await this.getResource(`/set-status-not-sold`, obj, 'POST');
    }

}