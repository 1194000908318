import {find} from "lodash/collection";
import {findIndex, head, last} from "lodash/array";
import React, {useContext} from "react";
import {mclc} from "./modal-create-lot-content";
import {modalCreateLotContext} from "./modal-create-lot";

const CategoryBlock = (props) => {

    const {parent_category_id, defaultSelect} = props;
    const data = useContext(modalCreateLotContext);

    const {
        setCurrentCategoryId,
        all_categories,
        phrases: {
            ak_select_main_cat, ak_au_phrase_second_category, ak_au_phrase_change_category, ak_au_no_matching_subcategory
        }
    } = data

    const {blockData, setBlockData, selectedCategories, setCategory, objParentCategories} = useContext(mclc);

    const getDescriptionBlock = () => {

        if (defaultSelect) {

            console.log(defaultSelect)
            const {category_description} = find(all_categories, {auction_category_id: defaultSelect});
            return category_description
        }
        return null
    }

    const description = getDescriptionBlock()
    const firstEl = head(selectedCategories)

    const onChangeCategory = React.useCallback((e) => {
        const newCategory = parseInt(e.target.value);
        setCurrentCategoryId(newCategory);
        setCategory(newCategory);
    }, []);


    const getOptions = () => {
        return objParentCategories[parent_category_id].map((val, key) => {
            const {auction_category_id, category_title} = val

            if (auction_category_id === 56) {
                return
            }

            return (
                <option key={key} value={auction_category_id}>
                    {category_title}
                </option>
            )
        })
    }

    const getDeleteButtoon = () => {

        if (firstEl === defaultSelect) {
            return null
        }

        const removeBlockCategories = () => {
            const el = findIndex(blockData, {parent_category_id})
            const newBlockData = [...blockData.slice(0, el)]
            const {defaultSelect: currentCategory} = last(newBlockData)
            setCurrentCategoryId(currentCategory)
            setBlockData(newBlockData);
        }

        return (
            <button onClick={() => removeBlockCategories()}
                    type="button"
                    className="btn btn-outline-secondary btn-sm ml-2">
                <span aria-hidden="true">&times;</span>
            </button>
        );
    }

    const phraseLabel = (firstEl === defaultSelect) ? ak_select_main_cat : ak_au_phrase_second_category

    const optionNoMatchingSubCategory = (parent_category_id !== 0)
        ? <optgroup label="----------------" className="divider">
            <option value={parent_category_id}>{ak_au_no_matching_subcategory}</option>
        </optgroup>
        : null;

    return (
        <div className="col-12 no-gutters mb-3">
            <div className="col-lg-6 col-10 ">
                <label className='title font-weight-bold'>{phraseLabel}</label>
                <div className='d-flex'>
                    <select
                        value={defaultSelect}
                        onChange={(e) => onChangeCategory(e)}
                        className="custom-select custom-select-sm">
                        <option value={0}>{ak_au_phrase_change_category}</option>
                        {getOptions()}
                        {optionNoMatchingSubCategory}

                    </select>
                    {getDeleteButtoon()}
                </div>
            </div>
            <span className="form-text font-italic text-secondary">
                    {description}
                </span>
        </div>
    )
}

export default CategoryBlock