import React, { useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Badge, Dropdown, DropdownButton } from "react-bootstrap";
import { find } from "lodash/collection";
import {getQueryLinkParams} from "../../../helpers/routing-helper";
import {getLinkData} from "../../../redux/lots/action";
import {useNavigate} from "react-router-dom";

const LotsTypeButtons = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const query = getQueryLinkParams();
    const lotsState = useSelector((state) => state.lots);

    const { totalMultiLots, totalSimpleLots, totalAllLots, phrases } = lotsState;
    const { type, user_lots_type, lotType = 'all' } = query;
    const { ak_au_all_lots, ak_au_simple_lots, ak_au_multi_lots } = phrases;

    const lotTypeList = useMemo(() => [
        { title: ak_au_all_lots, total: totalAllLots, lotType: 'all' },
        { title: ak_au_simple_lots, total: totalSimpleLots, lotType: 'simple' },
        { title: ak_au_multi_lots, total: totalMultiLots, lotType: 'multi' },
    ], [totalAllLots, totalSimpleLots, totalMultiLots]);

    const toggleDirectionButton = (e, lotType) => {
        e.preventDefault();
        const queryLink = dispatch(getLinkData({lotType, page: 1}));
        navigate(`?${queryLink}`);
    };

    const listItem = useMemo(() => {
        return lotTypeList.map(({ title, total, lotType }) => {

            return (
                <Dropdown.Item key={lotType} as="button" onClick={(e) => toggleDirectionButton(e, lotType)}>
                    {title}
                    <Badge pill variant="light">
                        {total}
                    </Badge>
                </Dropdown.Item>
            );
        });
    }, [lotTypeList]);

    const { title } = useMemo(() => find(lotTypeList, { lotType }), [lotTypeList, lotType]);

    if (user_lots_type === 'user_lots' && type === 'for_sales' && totalMultiLots > 0) {
        return (
            <div className="shop-select m-1">
                <DropdownButton size='sm' variant="info btn-rg" id="dropdown-item-order-button" title={title}>
                    {listItem}
                </DropdownButton>
            </div>
        );
    }

    return null;
}

export default LotsTypeButtons;
