// ���������� ���� � �������
// all_categories = ��� ��������� ��������
// onChangeCategory = ������� ������� ���������� ���������
// show = �� ������� ��������� true ��� ���� ���� ���� �����������

import React, {Component} from 'react'
import {find, groupBy} from "lodash/collection";
import findIndex from "lodash/findIndex";
import last from "lodash/last";

const categoriesData = (View) => {

    return class extends Component {
        state = {
            categoriesComponents: [],
            category_id: null
        };

        componentDidMount() {

            this.setCategoriesComponents(0, 0)
        }

        componentDidUpdate(prevProps, prevState) {

            const {categoriesComponents} = this.state;

            if (prevState.categoriesComponents !== categoriesComponents && categoriesComponents.length > 0) {
                const last_el = last(categoriesComponents);

                const category_id = (parseInt(last_el.children_category_id) === 0)
                    ? last_el.parent_category_id : last_el.children_category_id;

                if (parseInt(category_id) !== 0) {
                    this.setState({
                        category_id: parseInt(category_id)
                    });

                    this.props.onChangeCategory( parseInt(category_id));
                }
            }

            if (prevProps.show !== this.props.show) {
                if (!this.props.show) {
                    this.deleteComponentCategories(0);
                    this.setState({
                        category_id: null
                    });
                }
            }

        }

        //�������� ������ ��� �������� ���������� ���������
        deleteComponentCategories = (parent_category_id) => {
            const {categoriesComponents} = this.state;
            const index = findIndex(categoriesComponents,
                {'parent_category_id': parent_category_id});

            this.setState(({categoriesComponents}) => {
                const newArray = [
                    ...categoriesComponents.slice(0, index),

                ];
                return {
                    categoriesComponents: newArray
                }
            });
        };

        // ��������� ������ ��� �������� ���������� ����������
        setCategoriesComponents = (children_category_id, parent_category_id) => {
            const auction_categories = groupBy(this.props.all_categories, 'parent_category_id');

            this.setChildId(children_category_id, parent_category_id);

            if (children_category_id in auction_categories) {

                this.setState(({categoriesComponents}) => {
                    const newArray = [
                        ...categoriesComponents,
                        {
                            'parent_category_id': children_category_id,
                            'children_category_id': 0,
                            'categories': auction_categories[children_category_id],
                            'onChangeFunc': this.setCategoriesComponents,
                            'onDelete': this.deleteComponentCategories
                        }
                    ];

                    return {
                        categoriesComponents: newArray
                    }
                });
            }
        };

        setChildId = (id, parent_category_id) => {
            const {categoriesComponents} = this.state;

            const component = find(categoriesComponents,
                {'parent_category_id': parent_category_id});

            const index = findIndex(categoriesComponents,
                {'parent_category_id': parent_category_id});

            if (component !== undefined) {
                component.children_category_id = id;

                this.setState(({categoriesComponents}) => {
                    const newArray = [
                        ...categoriesComponents.slice(0, index),
                        component,
                    ];
                    return {
                        categoriesComponents: newArray
                    }
                });
            }
        };

        render() {
            return <View {...this.props} {...this.state}/>
        }
    }
};
export default categoriesData