import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import helpers from "../../../helpers/global-helper";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import {Thumbnails} from "yet-another-react-lightbox/plugins";
import './mig.css'
import useImageGalleryFn from "./useImageGalleryFn";

const ModalImageGallery = React.memo(() => {

    const {modalShow, imageIndex, attachments} = useSelector(
        state => state.imageGalleryReducer
    );

    const {closeModalImg} = useImageGalleryFn()

    const images = useMemo(() => {
        if (modalShow) {
            // Assuming attachments is an array of objects with a `viewUrl` property
            return attachments.map((attachment) => ({
                src: `${helpers.getBaseUrl()}/${attachment.viewUrl}`,
            }));
        }
    }, [attachments, modalShow]);

    return (
        <>
            {modalShow && (
                <Lightbox
                    open={modalShow}
                    close={closeModalImg}
                    slides={images}
                    plugins={[Thumbnails]}
                    index={imageIndex}
                    carousel={{finite: true, preload: 6}}
                    controller={{closeOnBackdropClick: true, closeOnPullUp: true}}
                />
            )}
        </>
    );
}, (prevProps, nextProps) => (prevProps.modalShow === nextProps.modalShow && prevProps.attachments === nextProps.attachments));

export default ModalImageGallery;
