import React, {createContext, memo, useContext, useMemo} from 'react';
import './lots-list-item.css';

import LotHeaderInfo from "../../../lot-components/lot-header-info";
import AdminLotButtons from "../../../lot-components/admin-lot-buttons";
import ThirdBlock from "./third-block";
import FourthBlock from "./fourth-block";
import BidAndBuyNowBlock from "../../../lot-components/bids-and-buynow-block";
import AnswerForm from "./answer-form";

import LotListThumbnail from "../../../lot-components/lot-list-thumbnail";
import useIsLotSelected from "./useIsLotSelected";
import {LotsContext} from "../../auction-lots-listing";

const lotContext = createContext();
export {lotContext}
const LotsListItem = memo((props) => {

    const {lotData} = props
    const {auction_id, color_background = 0} = lotData

    const {promotion_color, show_img_lots_list} = useContext(LotsContext)
    const selected = useIsLotSelected(auction_id);

    const promotion = useMemo(() => ({
        backgroundColor: color_background === 1 ? promotion_color : null
    }), [color_background, promotion_color]);

    return (
        <lotContext.Provider value={{lotData}}>
            <div style={promotion}
                 className={`container-fluid rounded shop-list-wrap shop-list-mrg2 shop-list-mrg-none mb-15 ${selected ? 'on-selected-lot' : ''}`}>

                <div className="row align-items-center">
                    {show_img_lots_list ?
                        <div className="col-lg-2 col-sm-3 col-12">
                            <div className="row justify-content-center">
                                <LotListThumbnail/>
                            </div>
                        </div> : null}

                    <div className={!show_img_lots_list ? 'col-12' : 'col-lg-10 col-sm-9 col-12'}>
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 px-sm-1">
                                <LotHeaderInfo/>
                                <AdminLotButtons/>
                            </div>
                            <div
                                className="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-12 py-2 py-sm-0 px-sm-2 align-self-center text-muted">
                                <ThirdBlock/>
                            </div>

                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-3 col-5 px-sm-2 align-self-center text-muted">
                                <FourthBlock/>
                            </div>

                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-5 col-7 px-sm-2 align-self-center text-muted">
                                <BidAndBuyNowBlock/>
                            </div>
                        </div>
                    </div>
                    <AnswerForm/>
                </div>
            </div>
        </lotContext.Provider>
    )
});

export default LotsListItem;