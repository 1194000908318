import React, {Suspense} from "react"

const WithSuspense = (props) => {

    return (
        <Suspense fallback={null}>
            {props.children}
        </Suspense>
    )
}

export default WithSuspense