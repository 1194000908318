import {useDispatch} from "react-redux";
import {useCallback} from "react";
import {setDataImageGallery} from "../../../redux/modals/modal-image-gallery/actionImageGallery";
import {isEmpty} from "lodash/lang";

const useImageGalleryFn = () => {
    const dispatch = useDispatch();

    // ������� �������, ������� ������������ ������ �������� ���������� ����
    const showModalImg = useCallback((arrObj = []) => {

        if (isEmpty(arrObj)){
            return false;
        }

        _setData({
            modalShow: true,
            attachments: arrObj
        })
    }, [dispatch]);

    const closeModalImg = useCallback(() => {
        _setData({modalShow: false, attachments: []})
    }, [dispatch]);

    const _setData = (obj) => {
        dispatch(setDataImageGallery(obj));
    }

    return {showModalImg, closeModalImg};
}
export default useImageGalleryFn
