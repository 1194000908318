import { applyMiddleware, compose, createStore } from 'redux';
import {thunk} from 'redux-thunk';
import rootReducer from './reducers';

export default function configureStore(preloadedState) {
    const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    return createStore(
        rootReducer(),
        preloadedState,
        composeEnhancer(
            applyMiddleware(
                thunk
            ),
        ),
    );
}
