import React, {useContext, useMemo} from 'react'
import {filter} from "lodash/collection"
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";
import {LotsContext} from "../../auction-lots-listing/auction-lots-listing";

const BadgesBlock = () => {
    const {lotData} = useContext(lotContext);

    const {
        user_lots_type, type,
        user_id,
        visitor: {
            user_id: visitor_user_id,
            permissions: {
                auctions: {
                    view_all_bids
                }
            }
        },
        phrases: {ak_au_new, ak_au_Indicator_copy, ak_au_autobid, ak_au_you_top_bid, ak_au_you_rebid, ak_au_hidden}
    } = useContext(LotsContext)


    const {
        user_id: auction_user_id, reedit_count, quantity_copies, status, copy,
        phrase_copy, autobid_user_id, top_bidder, bid_count, hide_lot
    } = lotData;

    const userBidIndicator = useMemo(() => {
        if (status === 'active' && user_id !== auction_user_id) {
            let phrase_bage;
            let clazz;

            if (type === 'watch_lots' || (type === 'for_sales' && user_lots_type === 'auction_lots')) {
                if (user_id === top_bidder && user_id !== 0) {
                    clazz = "badge badge-success btn-rg";
                    phrase_bage = (user_id === autobid_user_id) ? ak_au_autobid : ak_au_you_top_bid;
                } else if (bid_count > 0 && user_id !== top_bidder) {
                    clazz = "badge badge-danger";
                    phrase_bage = ak_au_you_rebid
                } else {
                    return null;
                }

                return <span className={clazz}>{phrase_bage}</span>
            }

            if (user_lots_type === 'user_bidding') {
                // ��� ����� ����� ��� ��������� ������� ���� �����
                if (user_id === top_bidder) {
                    clazz = "badge badge-success btn-rg";
                    phrase_bage = (user_id === autobid_user_id) ? ak_au_autobid : ak_au_you_top_bid;
                } else {
                    clazz = "badge badge-danger";
                    phrase_bage = ak_au_you_rebid

                }
                return <span className={clazz}>{phrase_bage}</span>
            }
        }
        return null;
    }, [
        status,
        user_id,
        auction_user_id,
        type,
        user_lots_type,
        top_bidder,
        bid_count
    ]);

    const badgeArr = useMemo(() => {
       const arr = [
            {
                phrase: ak_au_new,
                condition: !reedit_count && status === 'active'
            },
            {
                phrase: ak_au_hidden,
                clazz: 'badge-warning',
                condition: hide_lot && status === 'sale' && (visitor_user_id === auction_user_id || view_all_bids)
            },
            {
                phrase: ak_au_Indicator_copy,
                condition: status === 'inactive_copy' && copy
            },
            {
                phrase: phrase_copy,
                condition: quantity_copies >= 1 && status === 'finalized' && phrase_copy !== undefined
            }
        ]

        return filter(arr, (b) => {
            return b.condition
        })
    },[status, quantity_copies, copy, hide_lot, visitor_user_id])

    return (
        <>
            {badgeArr.map((item, key) => {
                const {phrase, clazz = 'badge-danger'} = item
                return (
                    <span key={key} className={`badge ${clazz} mx-1 list-badge`}>{phrase}</span>
                )
            })}
            {userBidIndicator}
        </>
    )
}
export default BadgesBlock

