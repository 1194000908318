import {SET_SIDEBAR_LOADING_LOTS_DATA, SET_SIDEBAR_LOTS_DATA, SET_TYPE_SIDEBAR} from "../../types";

const initialState = {
    type: 'new',
    sidebarLotsData: [
        {type: 'new', order: 'date', sorting: 'placement_date', direction: 'desc', lots: []},
        {type: 'top', order: 'bids', sorting: 'bids', direction: 'desc', lots: []},
        {type: 'ending', order: 'time', sorting: 'expiration_date', direction: 'asc', lots: []}
    ],
    loading: true
}

export const NewPopularEndingSideBarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TYPE_SIDEBAR:
            return {...state, type: action.payload}
        case SET_SIDEBAR_LOTS_DATA:
            return {...state, sidebarLotsData: action.payload}
        case SET_SIDEBAR_LOADING_LOTS_DATA:
            return {...state, loading: action.payload}
        default:
            return state;
    }
}