import React from "react"
import {useSelector} from 'react-redux';
import WithSuspense from "../../../repeating-components/wrapper-components/with-suspense";
import {useMediaQuery} from "react-responsive";

const Disclaimer = React.lazy(() => import('../disclaimer'));
const HiddingInformation = React.lazy(() => import('../hidding-information'));
const ButtonsHiddenShowAllLots = React.lazy(() => import('../buttons-hidden-show-all-lots'));

const MainLotView = (props) => {
    const {filterTabNav, lotsData}  =  useSelector(state => state.lots);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const clazz = (filterTabNav === 'grid' || lotsData.length === 0) ? "row justify-content-center ht-products " : null;

    return (
        <div className={`col-12 ${isMobile ? 'p-1' : 'p-2'}`}>
            <WithSuspense>
                <ButtonsHiddenShowAllLots/>
            </WithSuspense>
            <div className="shop-bottom-area">
                <div className="tab-content jump">
                    <div key={filterTabNav} id={filterTabNav} className="tab-pane active">
                        <WithSuspense><Disclaimer/></WithSuspense>
                        <div className={clazz}>
                            <WithSuspense><HiddingInformation/></WithSuspense>
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainLotView;