import {SET_PARAMS_SORTING_SETTINGS} from "../../types";

const initialState = {
    modalShow: false,
}

export const sortingSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PARAMS_SORTING_SETTINGS:
            return {...state, ...action.payload};
        default:
            return state
    }
}