import {SUBSCRIPTION_CATEGORY_SET_DATA} from "../../types";

const initialState = {
    modalShow: false,
    category_id: 0,
    action_user_id: 0
}

export const modalSubscriptionCategoryReducer = (state = initialState, action) => {

    switch (action.type) {
        case SUBSCRIPTION_CATEGORY_SET_DATA :
            return {...state, ...action.payload};
        default:
            return state;
    }

}