import React from "react";
import {find} from "lodash/collection";
import { Icon } from '@iconify/react';
import walletIcon from '@iconify/icons-simple-line-icons/wallet';
import {useDispatch} from "react-redux";

import {onChangeButtonCategorySettings} from '../../../redux/modals/modal-category-settings/actionCategorySettings'

const ButtonCategorySettings = (props) => {

    const {auction_category_id, all_categories, visitor} = props

    const dispatch = useDispatch();

    const onChangeButton = () => {
        dispatch(onChangeButtonCategorySettings(auction_category_id));
    }

    if (auction_category_id && visitor.user_id){
        const {category_settings} = find(all_categories, {auction_category_id});
        const calzz = (category_settings)? 'btn btn-danger btn-rr btn-sm' : 'btn btn-success btn-sm btn-rg'

        return (
            <button type="button" onClick={()=> onChangeButton()} className={calzz}>
                <span className='font-weight-bold'><Icon icon={walletIcon} /></span>
            </button>
        )

    }

    return null

}
export default ButtonCategorySettings;