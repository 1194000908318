import {SET_DATA_TITLE_MODAL} from "../../types";

const initialState = {
    modalShow: false,
    title: null,
    category_id: 0,
    prefix_id: 0,
    errors: {
        category: false,
        title: false,
        prefix: false,
    }
}

export const modalSetTitleReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_DATA_TITLE_MODAL :
            return {...state, ...action.payload};
        default:
            return state;
    }

}