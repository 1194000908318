import React, {useContext, useMemo} from 'react'
import BidAndFollowersBlock from "../../../../lot-components/bid-and-followers-block";
import Countdown from "../../../../lot-components/countdown";
import DayOffBlock from "../../../../lot-components/day-off-block";
import {includes} from "lodash/collection";
import {useSelector} from "react-redux";
import {LotsContext} from "../../../auction-lots-listing";
import {lotContext} from "../lots-list-item";
import {getQueryLinkParams} from "../../../../../helpers/routing-helper";

const FourthBlock = () => {

    const {
        visitor: {option_display_end_time},
        time_wait_pay,
        time_wait_take,
        time_wait_send,
    } = useContext(LotsContext);

    const {lotData} = useContext(lotContext);
    const {status, waitforpay_date, send_date, take_date} = lotData;
    const {type, user_lots_type} = getQueryLinkParams();

    return useMemo(() => {
        const getDateDisplay = (date) => {
            return (option_display_end_time || option_display_end_time === undefined)
                ? <Countdown date={date}/>
                : <DayOffBlock/>
        }
        const getTimeByStatus = () => {
            if (status === 'waitforpay') {
                return parseInt(waitforpay_date) + parseInt(time_wait_pay);
            } else if (status === 'take') {
                return parseInt(take_date) + parseInt(time_wait_take);
            } else if (status === 'send') {
                return parseInt(send_date) + parseInt(time_wait_send);
            } else {
                return null;
            }
        }

        if (includes(['sale', 'no_pay', 'nosale', 'finalized', 'inactive_copy'], status) || type === 'all_user_bets') {
            return <DayOffBlock/>;
        }

        if (includes(['active', 'vacation'], status) || type === 'no_sale' || user_lots_type === 'archive_lots') {
            return <BidAndFollowersBlock/>;
        }

        if (includes(['waitforpay', 'take', 'send'], status)) {
            const date = getTimeByStatus()
            return getDateDisplay(date);
        }

    }, [status, type, user_lots_type, option_display_end_time]);
}
export default FourthBlock