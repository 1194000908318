import React, {useContext, useMemo} from 'react';
import {find} from "lodash/collection";
import {isEmpty} from "lodash/lang";
import {useSelector} from "react-redux";
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";
import {LotsContext} from "../../auction-lots-listing/auction-lots-listing";

const FormSending = () => {
    const {phrases} = useContext(LotsContext);
    const topBiddersDeliveryMethod = useSelector(state => state.lots.topBiddersDeliveryMethod);
    const {lotData: {top_bidder, finish_time_for_send, delivery_id}} = useContext(lotContext);

    return useMemo(() => {

        if (isEmpty(topBiddersDeliveryMethod)) {
            return null;
        }

        if (!delivery_id) {
            return null
        }

        const {delivery_name, delivery_active = 0, delivery_info} = find(topBiddersDeliveryMethod[top_bidder], {delivery_id});

        if (!delivery_active) {
            return null
        }

        const phrase_delivery_info = `(${phrases[delivery_name]}) ${delivery_info}`;

        return (
            <div>
                {phrase_delivery_info}
                <div>
                    {phrases.ak_au_ph_you_need_to_send.replace(/\{finish_time_for_send\}/, finish_time_for_send)}
                </div>
            </div>
        );
    }, [finish_time_for_send, delivery_id, topBiddersDeliveryMethod]);

}
export default FormSending;