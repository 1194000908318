import {SET_ONLINE_MODERATORS, SET_SHOW_BLOCK_ONLINE_MODERATORS} from "../../types";

const initialState = {
    onlineStaff: [],
    show: true
}

export const auctionModeratorsSideBarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ONLINE_MODERATORS:
            return {...state, onlineStaff: action.payload}
        case SET_SHOW_BLOCK_ONLINE_MODERATORS:
            return {...state, show: action.payload}
        default:
            return state;
    }
}