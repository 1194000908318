import React, {useContext, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {sendAnswer} from "../../../redux/lots/action";
import Editor from "../../repeating-components/editor";
import GlobalHelper from "../../../helpers/global-helper";
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";
import './form-answer.css';


const FormAnswear = (props) => {

    const dispatch = useDispatch();

    const [error, setError] = useState(false)
    const [message, setMessage] = useState('')

    const {lotData} = useContext(lotContext);
    const {auction_id, status} = lotData;

    const {phrases: {are_you_sure_you_want_to_send, ak_au_aprove_pay, ak_au_send_lot, ak_au_take_lot}}
        = useSelector((state) => {
        return state.lots
    });

    let buttonPhrase = 'ok';

    if (status === 'waitforpay') {
        buttonPhrase = ak_au_aprove_pay

    }
    if (status === 'send') {
        buttonPhrase = ak_au_send_lot
    }

    if (status === 'take') {
        buttonPhrase = ak_au_take_lot
    }

    const objEditorParams = {
        handleMessageChange:setMessage,
        message:message,
        showToolbar:false,
    }

    const submitForm = (e) => {
        e.preventDefault();

        const cleanMessage = GlobalHelper.removeTagsText(message);

        if (!cleanMessage.length && !error) {
            setError(true);
            return false
        }

        dispatch(sendAnswer(auction_id, cleanMessage));
        // setMessage('')
        // dispatch(updateLotsData())
    }

    return useMemo(() => {
        const erroBlock = () => {

            if (error) {
                return (
                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                        <strong>{are_you_sure_you_want_to_send}</strong>
                    </div>
                )
            }
            return null;
        }

        return (
            <div className="col-12 py-2">
                {erroBlock()}
                <form name='answerForm' className="answerForm">
                    <div className="form-group">
                        <label htmlFor="payment_info">
                            {props.children}
                        </label>
                        <Editor {...objEditorParams}/>
                    </div>
                    <input type="submit" onClick={(e) => {
                        submitForm(e)
                    }} value={buttonPhrase} className="btn btn-info btn-rg btn-sm"/>
                </form>
            </div>
        );

    }, [message, error, buttonPhrase, dispatch, auction_id])


}

export default FormAnswear;