import React, {lazy} from 'react';
import {useSelector} from 'react-redux';
import ModalInlineModCheck from "./modal-inline-mod-check";
import ModalImageGallery from "./modal-image-gallery";
import ModalCreateLot from "./modal-create-lot";
import {createPortal} from "react-dom";


// ������� �������������� ��������� ����
// const ModalImageGallery = lazy(() => import('../modals/modal-image-gallery'));
// const ModalInlineModCheck = lazy(() => import('../modals/modal-inline-mod-check'));
const ModalSortingSettings = lazy(() => import('./modal-sorting-settings'));
const ModalPrefix = lazy(() => import('./modal-prefix'));
const ModalSubscriptionPrefix = lazy(() => import('./modal-subscription/modal-subscription-prefix/modal-subscription-prefix'));
const ModalSubscriptionCategory = lazy(() => import('./modal-subscription/modal-subscription-category/modal-subscription-category'));
const ModalPromotionLots = lazy(() => import('./modal-promotion-lots'));
const ModalCategorySettings = lazy(() => import('./modal-category-settings/modal-category-settings'));


const modalContainer = document.getElementById('modal-root');

const Modals = () => {
    const {
        modalInlineShow, modalFollowPrefixShow, modalSortingSettingShow, modalCreateLotShow,
        modalPrefixShow, modalFollowCategoryShow, modalPromotionShow, modalCategorySettingsShow, modalImageGalleryShow,
    } = useSelector(state => {
        const {
            modalCreateLot,
            modalSubscriptionPrefix,
            sortingSetting,
            modalSubscriptionCategory,
            modalPromotion,
            imageGalleryReducer,
            categorySettingsReducer,
            modalPrefix,
            modal,
        } = state

        return {
            modalCreateLotShow: modalCreateLot.showModal,
            modalInlineShow: modal.modalShow,
            modalFollowPrefixShow: modalSubscriptionPrefix.modalShow,
            modalSortingSettingShow: sortingSetting.modalShow,
            modalFollowCategoryShow: modalSubscriptionCategory.modalShow,
            modalCategorySettingsShow: categorySettingsReducer.modalShow,
            modalPrefixShow: modalPrefix.modalShow,
            modalImageGalleryShow: imageGalleryReducer.modalShow,
            modalPromotionShow: modalPromotion.modalShow,
        }
    });

    return (
        createPortal(
            <>
                {modalImageGalleryShow && <ModalImageGallery/>}
                {modalSortingSettingShow && <ModalSortingSettings/>}
                {modalPrefixShow && <ModalPrefix/>}
                {modalFollowPrefixShow && <ModalSubscriptionPrefix/>}
                {modalFollowCategoryShow && <ModalSubscriptionCategory/>}
                {modalPromotionShow && <ModalPromotionLots/>}
                {modalCategorySettingsShow && <ModalCategorySettings/>}
                {modalInlineShow && <ModalInlineModCheck/>}
                {modalCreateLotShow && <ModalCreateLot/>}
            </>,
            modalContainer
        )
    );
}

export default Modals;
