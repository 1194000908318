import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {installFilters} from "../../../redux/sidebars/filter-sidebar/actionFilterSideBar";
import queryString from "query-string";
import {find} from "lodash";
import {getLinkData} from "../../../redux/lots/action";

const useLotsDataWatcher = () => {
    const {userSortData, credits} = useSelector((state) => {
        const {lots} = state

        return{
            userSortData: lots.user_sort_data,
            credits: lots.visitor.credits,
        }
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const addEventListener = (selector, event, handler) => {
        const element = document.querySelector(selector);
        if (!element) {
            console.warn(`Element not found for selector: ${selector}`);
            return () => {
            };
        }
        element.addEventListener(event, handler);
        return () => element.removeEventListener(event, handler);
    };

    const handleLogoClick = useCallback((e) => {
        e.preventDefault();
        navigate('/');
    }, [navigate]);

    const handleNewLotsClick = useCallback((e) => {
        e.preventDefault();
        dispatch(installFilters({name: 'filter_new_lots', value: 1}));
        const queryLink = dispatch(getLinkData({user_lots_type: 'auction_lots', auction_category_id:0, page: 1}));
        navigate(`/?${queryLink}`);
    }, [navigate, dispatch]);

    const handleYourBidsClick = useCallback((e) => {
        e.preventDefault();
        const {sort_order: order = 'title', direction = 'asc', type = 'for_sales', user_lots_type = 'user_bidding'} =
            find(userSortData, {user_lots_type: 'user_bidding'});
        const query = queryString.stringify({order, direction, type, user_lots_type});
        navigate(`/auction-history/user-bidding?${query}`);
    }, [navigate, userSortData]);

    // const handleCreateLot = useCallback((e) => {
    //     e.preventDefault();
    //
    //
    //     if (Number(credits) < 0) {
    //         window.location.href = 'credits/';
    //     }
    //
    //     if (Number(credits) >= 0) {
    //         dispatch(onToggleModal(true))
    //     }
    //
    // },[credits])

    // useEffect(() => {
    //     const elements = document.querySelectorAll('a.create_auction');
    //
    //     if (elements.length === 0) {
    //         console.warn(`Elements not found for selector: 'a.create_auction'`);
    //         return;
    //     }
    //
    //     // ������� ������ ��� �������� ������������
    //     const cleanupHandlers = [];
    //
    //     elements.forEach(element => {
    //         const newElement = element.cloneNode(true);
    //         element.replaceWith(newElement);
    //
    //         // ���������� ����������
    //         const handler = (event) => handleCreateLot(event);
    //
    //         // ��������� ����������
    //         newElement.addEventListener('click', handler);
    //
    //         // ��������� ��� ��������
    //         cleanupHandlers.push(() => newElement.removeEventListener('click', handler));
    //     });
    //
    //     // ������� ������� ��� ���������������
    //     return () => {
    //         cleanupHandlers.forEach(cleanup => cleanup());
    //     };
    // }, [handleCreateLot]);

    useEffect(() => addEventListener('div#logo div a', 'click', handleLogoClick), [handleLogoClick]);
    useEffect(() => addEventListener('#navigation li.navTab.auctions.selected a.navLink', 'click', handleLogoClick), [handleLogoClick]);
    useEffect(() => addEventListener('#navigation ul.secondaryContent.blockLinksList li a.ak_au_new_lots', 'click', handleNewLotsClick), [handleNewLotsClick]);
    useEffect(() => addEventListener('#navigation ul.secondaryContent.blockLinksList li a.ak_au_your_bids', 'click', handleYourBidsClick), [handleYourBidsClick]);
};

export default useLotsDataWatcher;