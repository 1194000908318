import SubscriptionsApiService from "../../services/subscriptions-api-service";
import {SUBSCRIPTION_CATEGORY_SET_DATA, SUBSCRIPTION_PREFIX_SET_DATA, SUBSCRIPTION_SET_DATA, UPDATE_LOTS_DATA} from "../types";
import {orderBy} from "lodash/collection";
import {setAllCategories, setUserPrefixes} from "../lots/action";
import {matchPath} from "react-router-dom";

const subscriptionsApiService = new SubscriptionsApiService();

export function getDataSubscriptions(obj) {
    return async (dispatch) => {

        dispatch({type: SUBSCRIPTION_SET_DATA, payload: {subscriptionLoading: false}});

        subscriptionsApiService.getDataSubscriptions(obj).then((json) => {
            const {user_owner_data, subscriptionPrefixes = {}, subscriptionCategories = {}} = json;
            dispatch({type: UPDATE_LOTS_DATA, payload: {user_owner_data}});
            const objSubscriptions = {subscriptionPrefixes, subscriptionCategories, subscriptionLoading: false}

            dispatch(setDataSubscription(objSubscriptions));
        })
    }
}

export function setDataSubscription(obj) {
    return (dispatch) => {
        dispatch({type: SUBSCRIPTION_SET_DATA, payload: obj});
    }
}

export function setSubscriptionCategory(obj) {
    return dispatch => {
        subscriptionsApiService.setSubscriptionCategory(obj).then((json) => {
            const {_redirectStatus, all_categories, subscriptionCategories} = json

            if ('error' in json) {
                alert(json.error[0])
                return
            }

            if (_redirectStatus === 'ok') {
                const allCategories = orderBy(all_categories, ['lft'], ['asc']);
                dispatch(setAllCategories(allCategories));

                if ('user_id' in obj) {
                    dispatch(setDataSubscription({subscriptionCategories: subscriptionCategories, subscriptionLoading: false}));
                }
            }

            dispatch(setParamsSubscriptionCategoryModal());
        });
    }
}

export function setSubscriptionPrefix(obj) {
    return dispatch => {
        subscriptionsApiService.setSubscriptionPrefix(obj).then((json) => {

            const {_redirectStatus, user_prefixes} = json

            if ('error' in json) {
                alert(json.error[0])
                return
            }

            if (_redirectStatus === 'ok') {
                dispatch(setUserPrefixes(user_prefixes));

                const path = {path: "/subscriptions/:action"};
                const match = matchPath(path, window.location.pathname);

                if (match !== null) {
                    dispatch(setDataSubscription({subscriptionPrefixes: user_prefixes, subscriptionLoading: false}))
                }
            }
            dispatch(setParamsSubscriptionPrefixModal());
        });
    }
}

export function setParamsSubscriptionCategoryModal(category_id = 0, action_user_id = 0) {
    return (dispatch, getState) => {
        const {modalShow} = getState().modalSubscriptionCategory;
        dispatch({type: SUBSCRIPTION_CATEGORY_SET_DATA, payload: {modalShow: !modalShow, category_id, action_user_id}});
    }
}

export function setParamsSubscriptionPrefixModal(prefixId = 0, action_user_id = 0) {
    return (dispatch, getState) => {
        const {modalShow} = getState().modalSubscriptionPrefix ;
        dispatch({type: SUBSCRIPTION_PREFIX_SET_DATA, payload: {modalShow: !modalShow, prefix_id: prefixId, action_user_id}});
    }
}

export function disableSubscriptionPrefix(obj) {
    return async (dispatch) => {

        subscriptionsApiService.setSubscriptionPrefix(obj).then((json) => {

            const {_redirectStatus, user_prefixes} = json

            if ('error' in json) {
                alert(json.error[0])
                return
            }

            if (_redirectStatus === 'ok') {
                dispatch({type: SUBSCRIPTION_SET_DATA, payload: {subscriptionPrefixes: user_prefixes, subscriptionLoading: false}});
            }
        });
    }
}

export function disableSubscriptionCategory(obj) {
    return async (dispatch, getState) => {

        subscriptionsApiService.setSubscriptionCategory(obj).then((json) => {

            const {_redirectStatus, subscriptionCategories} = json

            if ('error' in json) {
                alert(json.error[0])
                return
            }

            if (_redirectStatus === 'ok') {

                const {auction_category_id} = obj;

                dispatch({type: SUBSCRIPTION_SET_DATA, payload: {subscriptionCategories: subscriptionCategories, subscriptionLoading: false}});
            }

        });
    }
}
