import {isNaN} from "lodash/lang";

export function getArchiveSwichData(y = NaN) {

    return (dispatch, getState) => {
        const {
            phrases:
                {
                    ak_au_archive_sale,
                    ak_au_archive_sales_category,
                    ak_au_ph_archive_unfinished_lots,
                    ak_au_ph_archive_category_unfinished_lots,
                    ak_au_ph_archive_unsold_lots,
                    ak_au_ph_archive_category_unsold_lots
                }
        } = getState().lots

        const date = new Date();
        const year = (!isNaN(y)) ? y : date.getFullYear();

        return [
            {
                title: ak_au_archive_sale,
                type: 'sale',
                order: 'date_of_sale',
                direction: 'desc',
                title_by_category: ak_au_archive_sales_category,
                link: `/archive/${year}/sold/`,
            },
            {
                title: ak_au_ph_archive_unfinished_lots,
                type: 'unfinished',
                order: 'time',
                direction: 'desc',
                title_by_category: ak_au_ph_archive_category_unfinished_lots,
                link: `/archive/${year}/wait/`,
            },
            {
                title: ak_au_ph_archive_unsold_lots,
                type: 'finalized',
                order: 'time',
                direction: 'desc',
                title_by_category: ak_au_ph_archive_category_unsold_lots,
                link: `/archive/${year}/no-sold/`,
            }
        ];
    }
}