import {SET_DATA_IMAGE_GALLERY} from "../../types";

const initialState = {
    modalShow: false,
    imageIndex: 0,
    attachments: [],
};

export const imageGalleryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA_IMAGE_GALLERY:
            return {...state, ...action.payload};
        default:
            return state
    }
}