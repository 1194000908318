import React, {useCallback, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {filter, find} from "lodash/collection";
import {removeFilter, resetFilter} from "../../../redux/sidebars/filter-sidebar/actionFilterSideBar";
import {getQueryLinkParams} from "../../../helpers/routing-helper";
import {useNavigate} from "react-router-dom";
import {getLinkData} from "../../../redux/lots/action";

const FilterTopBar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {auction_category_id} = getQueryLinkParams();

    const {lots: {phrases}, filterSidebar: {filterInput, blockData, loading}} = useSelector(state => state);

    const {
        ak_au_reset_filter,
        auctions_interesting_lots,
        ak_au_phrase_promotion
    } = phrases;

    const filters = useMemo(() => filter(filterInput, {'initial': false}), [filterInput]);

    const getDataFilter = useCallback((val) => {
        const {name, value} = val;

        const getBlockCategory = () => {
            return blockData ? find(blockData, {block: name}) : [];
        };

        switch (name) {
            case 'promotion':
                return {phrase: ak_au_phrase_promotion};
            case 'interesting':
                return {phrase: auctions_interesting_lots};
            case 'prefix_id':
                return {phrase: phrases[`category_prefix_${value}`]};
            default:
                const {phrase, block_category} = getBlockCategory();
                const found = find(block_category, ({filterData}) => filterData.value == value);
                return found ? {phrase: found.phrase} : {phrase};
        }
    }, [blockData, phrases]);

    const clearFilter = useCallback((data) => {
        dispatch(removeFilter(data));
        const queryLink = dispatch(getLinkData({page: 1}));
        const {pathname} = window.location;
        navigate(`${pathname}?${queryLink}`);
    }, [dispatch]);

    const getFilterButtons = useMemo(() => {
        if (loading || filters.length === 0) return null;

        return filters.map((val, key) => {
            if (auction_category_id !== 0 && val.name === 'filter_category') return null;

            const {phrase} = getDataFilter(val);
            return (
                <button key={key} type="button" onClick={() => clearFilter(val.name)}
                        className="btn btn-success btn-sm btn-rg m-1">
                    {phrase} <span aria-hidden="true">&times;</span>
                </button>
            );
        });
    }, [filters, loading, auction_category_id, getDataFilter, clearFilter]);

    const resetButton = filters.length > 0 && (
        <button type="button" onClick={() => {
            dispatch(resetFilter())
            const queryLink = dispatch(getLinkData({}));
            navigate(`?${queryLink}`);
        }} className="btn btn-success btn-sm btn-rr m-1">
            {ak_au_reset_filter}
        </button>
    );

    return filters.length > 0 ? (
        <div className="row mb-1">
            <div className="col-12">
                <div className="section-main">
                    <div className="m-1 d-flex flex-wrap">
                        {getFilterButtons}
                        {resetButton}
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default FilterTopBar;