import React, {useCallback, useEffect, useMemo, useState} from "react"
import {useSelector} from "react-redux";
import {groupBy} from 'lodash/collection'
import CategoryNavContent from "./category-nav-contant";
import CreateCategoriesContent from "./create-categories-content";
import {isNull} from "lodash/lang";
import {getQueryLinkParams} from "../../../helpers/routing-helper";

const CategoriesNav = () => {
    const [categories, setCategories] = useState(false)

    const {auction_category_id: current_category_id} = getQueryLinkParams();

    const {
        all_categories, phrases: {ak_au_ph_open}
    } = useSelector((state) => state.lots)

    useEffect(() => {
        const categories_by_parent_category = groupBy(all_categories, 'parent_category_id');
        const categories = (categories_by_parent_category[current_category_id] !== undefined)
            ? categories_by_parent_category[current_category_id] : false;
        setCategories(categories);
    }, [current_category_id])

    const getCategories = useMemo(() => {
        if (!categories) {
            return null;
        }

        return categories.map((value, key) => {
            return (
                <CreateCategoriesContent key={key} {...value}/>
            );
        });
    }, [categories])

    const showCategoryBlock = useCallback((e) => {
        e.target.className = 'd-none'
        const category_block = document.getElementById('category_block');
        category_block.className = '';
    }, [])

    if (isNull(getCategories)){
        return null;
    }

    return (
        <CategoryNavContent
            showCategoryBlock={showCategoryBlock}
            getCategories={getCategories}
            ak_au_ph_open={ak_au_ph_open}
        />
    )
}
export default CategoriesNav