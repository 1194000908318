import {SET_DAYS} from "../../types";

const initialState = {
    modalShow: false,
    amountDay: null,
    sendData: false
}

export const modalTradingDurationReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_DAYS :
            return {...state, ...action.payload};
        default: return state;
    }

}