import {SET_DATA_ONLINE_USERS, SET_SHOW_BLOCK_ONLINE_USERS} from "../../types";

export function setOnlineUsers(data) {
    return (dispatch) => {
        dispatch({type: SET_DATA_ONLINE_USERS, payload: data})
    }
}

export function showBlockOnlineUsers() {
    return (dispatch, getState) => {
        const {show} = getState().onlineUsersSidebar
        dispatch({type: SET_SHOW_BLOCK_ONLINE_USERS, payload: !show})
    }
}