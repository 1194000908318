import React, {useMemo, memo, useContext} from 'react';
import CheckBox from "./check-box";
import PrefixBlock from "./prefix-block";
import LinkTitle from "./link-title";
import './lot-title.css';
import BadgesBlock from "./badges -block";

import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";
import {LotsContext} from "../../auction-lots-listing/auction-lots-listing";

const LotTitle = () => {
    const {lotData} = useContext(lotContext);
    const {
        user_lots_type,
        visitor: {
        user_id: visitor_user_id,
        permissions: {
            auctions: {
                deleteLots,
                RestoreLots,
                view_all_bids
            }
        }
    }} = useContext(LotsContext);


    const {auction_id, user_id: auction_user_id, status, prefix_id} = lotData;

    const checkCondition = useMemo(() => {
        const statusType = ['nosale', 'sale', 'finalized', 'active'];
        return ((visitor_user_id === auction_user_id || deleteLots || RestoreLots || view_all_bids)
            && statusType.includes(status));

    }, [visitor_user_id, auction_user_id]);

    const {clazz_block, clazz_label} = useMemo(() => {
        let obj = {};

        obj.clazz_block = 'form-inline';
        obj.clazz_label = '';

        if (user_lots_type === 'archive_lots') {
            return obj
        }

        if (checkCondition) {
            obj.clazz_block = 'custom-control custom-checkbox';
            obj.clazz_label = 'custom-control-label';
        }
        return obj
    }, [user_lots_type, checkCondition]);


    return (
        <div className={clazz_block}>
            {user_lots_type !== 'archive_lots' && checkCondition && <CheckBox/>}
            <label data-action className={clazz_label} htmlFor={`lot_${auction_id}_title`}>
                <div className="d-flex justify-content-center">
                    <div className="align-self-start flex-fill">
                        {prefix_id ? <PrefixBlock/> : null}
                        <LinkTitle/>
                        <BadgesBlock/>
                    </div>
                </div>
            </label>
        </div>
    );
};

export default LotTitle;