
import {SET_PAGE_NAV_DATA} from "../types";
import {getQueryLinkParams} from "../../helpers/routing-helper";

export function setPageNavData(){
    return (dispatch, getState) => {
        const {total, perPage} = getState().lots;
        const {step, defaultViewPage} = getState().pageNav;
        const {page} = getQueryLinkParams()
        const lastPage = Math.ceil(total / perPage);
        const move = (step === lastPage) ? 1 : step;
        const activePage = (page <= 0) ? 1 : parseInt(page);

        const payload = {
            activePage,
            lastPage: lastPage,
            showTo: (activePage === lastPage || activePage + move > lastPage) ? lastPage : activePage + move,
            showFrom:  (activePage === lastPage) ? activePage - defaultViewPage : activePage - move,
            defaultViewPage: (defaultViewPage > lastPage) ? lastPage : defaultViewPage
        };

        dispatch(setParamsPageNav(payload));
    }
}

export function setParamsPageNav(obj){
    return (dispatch) => {
        dispatch({type: SET_PAGE_NAV_DATA, payload: obj})
    }
}