
import BaseApiService from "./base-api-service";

export default class ReibertApiService extends BaseApiService{

    constructor() {
        super();
        this._apiBase = `${this._baseUrl}/api`;
    }

// получаем дефолтные параметры для списков лотов ( фразы, css класы префиксов, список категорий аукциона)
    getLotsListParams = async (obj) => {
        return await this.getResource(`/get-lots-list-params`, obj);
    };
// получаем спиок лотов и все что с ним связано (эти данные постоянно изменяються)
    getUserLots = async (obj) => {
        return await this.getResource(`/get-user-lots`, obj);
    };
//сайдбар новые поулярные завершающии
    getLotsSideBar = async (obj) => {
        return await this.getResource(`/get-lots-sidebar`, obj);
    };
// получаем кол-во по каждому разделу сайдбара юзера
    getCountLotsInSidebar = async () => {
        return await this.getResource(`/get-count-lots-in-sidebar`);
    }
// получаем фразы и остальное по всем сайдбарам
    getDefaultParamsSideBar = async () => {
        return await this.getResource(`/get-default-params-sidebar`);
    };
    getInterestingLots = async (obj) => {
        return await this.getResource(`/get-interesting-lots`, obj);
    };
    getUserCategorySettings = async (obj) => {
        return await this.getResource(`/get-user-category-settings`, obj);
    }

    formPrefixData = async (obj) => {
        return await this.getResource(`/form-prefix-data`, obj, 'POST');
    }
}