import React, {useContext} from 'react'
import FormPaymentLot from "../../../../lot-components/form-payment-lot";
import FormSending from "../../../../lot-components/form-sending";
import FormTake from "../../../../lot-components/form-take";
import {LotsContext} from "../../../auction-lots-listing";
import {includes} from "lodash/collection";
import {isNull} from "lodash/lang";
import FormAnswear from "../../../../lot-components/form-answer";
import {lotContext} from "../lots-list-item";

const AnswerForm = () => {
    const {visitor, type, user_lots_type} = useContext(LotsContext);
    const {lotData:{status, open_disput_count, top_bidder, user_id}} = useContext(lotContext);

    if (user_lots_type === 'archive_lots') {
        return null
    }

    if (open_disput_count === 0) {

        let info = null

        if (status === 'waitforpay' && top_bidder === visitor.user_id && user_lots_type === 'purchases') {
            info = <FormPaymentLot/>
        }

        if (status === 'send' && user_id === visitor.user_id && !includes(['send', 'all_user_bets'], type)) {
            info = <FormSending/>
        }

        if (status === 'take' && user_lots_type === 'purchases' && top_bidder === visitor.user_id) {
            info = <FormTake/>
        }

        return !isNull(info) ? <FormAnswear>{info}</FormAnswear> : null;
    }

    return null;
};
export default AnswerForm;