import {SET_PREFIX_MODAL, UPDATE_LOTS_DATA} from "../../types";
import {find} from "lodash/collection";
import {findIndex} from "lodash/array";
import {isUndefined} from "lodash/lang";
import ReibertApiService from "../../../services/reibert-api-service";

export function setParamsModalPrefix(data) {
    return (dispatch) => {
        dispatch({type: SET_PREFIX_MODAL, payload: data});
    }
}

export function sendFormPrefix(chosenPrefix) {
    return (dispatch, getState) => {
        const {formPrefixData} = new ReibertApiService();
        const {lotsData} = getState().lots
        const {auctionIds} = getState().modalPrefix
        const obj = {select: true, prefix_id: chosenPrefix, auctions: JSON.stringify(auctionIds)}

        if (isUndefined(auctionIds)){
            return false;
        }

        let newLotsData = []
        auctionIds.map((item, key) => {
            let el = find(lotsData, {auction_id: parseInt(item)});
            el.prefix_id = chosenPrefix
            const index = findIndex(lotsData, {auction_id: parseInt(item)});
            lotsData[index].prefix_id = chosenPrefix
            newLotsData = [...lotsData.slice(0, index), el, ...lotsData.slice(index + 1)];
            return true
        });

        formPrefixData(obj).then(({_redirectStatus}) => {
            if (_redirectStatus === 'ok') {
                const obj = {lotsData: newLotsData}
                dispatch({type: UPDATE_LOTS_DATA, obj});
                dispatch(setParamsModalPrefix({modalShow: false}))
            }
        });
    }
}