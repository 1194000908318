import React, {useEffect} from 'react';
import './modal-inline-mod-check.css'
import ModalMainView from "./modal-components/modal-main-view";
import {useDispatch, useSelector} from "react-redux";
import {setErrors, setOption} from "../../../redux/modals/modal-inline-mod-check/actionModal";
import {createPortal} from "react-dom";
const modalContainer = document.getElementById('modal-root');
const ModalInlineModCheck = () => {

    const dispatch = useDispatch();


     const state= useSelector(state => {
       const {modal, lots} = state;
       return {modal, lots};
    });

    const {operation_copy, delete_type, category_id, handler_type, errors, modalShow} = state.modal
    const changeLots = state.modal.changeLots

    useEffect(() => {
        if (errors.length > 0) {
           dispatch(setErrors());
        }
    }, [operation_copy, delete_type, category_id, handler_type]);

    useEffect(()=>{
        if (modalShow){
            dispatch(setOption());
        }


    }, [changeLots]);

    return <ModalMainView/>


}

export default ModalInlineModCheck
