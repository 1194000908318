import {SET_FILTER, SET_FILTERS, SET_FILTERS_SIDEBAR_BLOCK_DATA, SET_FILTERS_SIDEBAR_LOADING} from "../../types";

const initialState = {
    blockData: [],
    filterInput: [],
    loading: true
};

export const filterSideBarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERS_SIDEBAR_BLOCK_DATA:
            return {...state, blockData: action.payload}
        case SET_FILTERS_SIDEBAR_LOADING:
            return {...state, loading: action.payload}
        case SET_FILTER:
            return {...state, filterInput: action.payload}
        case SET_FILTERS:
            return {...state, filterInput: action.payload}
        default:
            return state;
    }
}