import React, {useContext} from 'react';
import {useDispatch} from "react-redux";
import {getFormattedDate} from "../../../redux/lots/action";
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";
import {LotsContext} from "../../auction-lots-listing/auction-lots-listing";

const FormTake = React.memo(() => {
    const dispatch = useDispatch();
    const {lotData:{take_date}} = useContext(lotContext);

    const {
        phrases: {ak_au_ph_lot_send_for_you},
        time_wait_take,
    } = useContext(LotsContext);


    const finish_time_for_take = dispatch(getFormattedDate(time_wait_take + take_date));
    const lot_send_for_you = ak_au_ph_lot_send_for_you.replace(/\{finish_time_for_take\}/, finish_time_for_take);

    return (
        <div>
            <span dangerouslySetInnerHTML={{__html: lot_send_for_you}}/>
        </div>
    );
})
export default FormTake