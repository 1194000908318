import {filter, find, forEach} from 'lodash/collection';
import {SET_FILTER, SET_FILTERS, SET_FILTERS_SIDEBAR_BLOCK_DATA, SET_FILTERS_SIDEBAR_LOADING} from "../../types";
import {isEmpty, isUndefined} from "lodash/lang";
import queryString from "query-string";

export function setFiltersData(newArr) {
    return (dispatch) => {
        dispatch({type: SET_FILTERS_SIDEBAR_BLOCK_DATA, payload: newArr})
        dispatch({type: SET_FILTERS_SIDEBAR_LOADING, payload: false});
    }
}

export function setFilters(filters) {
    return (dispatch) => {
        dispatch({type: SET_FILTERS, payload: filters})
    }
}

//filterData
export function installFilters(obj) {
    return (dispatch, getState) => {

        const defaultFilters = getDefaultFilters();
        const {filterInput} = getState().filterSidebar

        const getNewArr = (obj, arr) => {
            const {name, value} = obj
            const defaultFilter = find(defaultFilters, {name});
            const initial = (defaultFilter.value == value) ? defaultFilter.initial : false;

            return arr.map(item => {
                if (item.name === name) {
                    item.value = value;
                    item.initial = initial;
                }
                return item
            });
        }

        let newArr = getNewArr(obj, filterInput)

        // � �� ���� ��� ���� ��� � ��� �������� �� ��� ����� ������ �������� ��� ���������
        // if (obj.name === 'filter_category') {
        //     const defaultFilterCategoryId = find(defaultFilters, {name: 'filter_category'})
        //     newArr = getNewArr(defaultFilterCategoryId, newArr);
        // }

        if (obj.name === 'prefix_id') {
            const defaultFilterPrefixId = find(defaultFilters, {name: 'prefix_id'})
            newArr = getNewArr(defaultFilterPrefixId, newArr);
        }

        const filters = filter(newArr, {'initial': false})
        const storageFilters = JSON.stringify(filters);
        localStorage.removeItem('selectUserFilter');
        localStorage.setItem('selectUserFilter', storageFilters);

        dispatch({type: SET_FILTER, payload: newArr});
    }
}

export function setFilter(obj) {
    return (dispatch) => {
        dispatch(installFilters(obj));
    }
}

export function getFilters() {
    return (dispatch, getState) => {

        const {visitor_user_id} = getState().lots
        const parsed = queryString.parse(window.location.search);

        let defaultFilter = getDefaultFilters();
        let arrFilters = [];

        // ��� ������� ������� �� ��� ���� ��� ����
        forEach(parsed, (val, key) => {
            const filterUrl = find(defaultFilter, {name: key});

            if (filterUrl !== undefined && filterUrl.value !== val) {
                arrFilters = [...arrFilters, {name: filterUrl.name, value: decodeURIComponent(val), initial: false}]
            }
        });

        // ���� �� ��� ��������� ��� �� � ��������� ������������ ����������� ��������
        if (isEmpty(arrFilters) && visitor_user_id !== 0) {
            arrFilters = JSON.parse(localStorage.getItem('selectUserFilter'));
        }

        defaultFilter = (isEmpty(arrFilters)) ? defaultFilter : defaultFilter.map((value) => {

            const userFilter = find(arrFilters, {name: value.name});

            if (isUndefined(userFilter)) {
                return value
            }

            return userFilter
        })

        return defaultFilter
    }
}

export function getDefaultFilters() {
    return [
        {name: 'promotion', value: 0, initial: true},
        {name: 'interesting', value: 0, initial: true},
        {name: 'filter_new_lots', value: 0, initial: true},
        {name: 'filter_start_price', value: '0', initial: true},
        {name: 'filter_current_price', value: 0, initial: true},
        {name: 'filter_delivery', value: 0, initial: true},
        {name: 'filter_sale_type', value: 0, initial: true},
        {name: 'filter_seller_rating', value: 0, initial: true},
        {name: 'filter_timeout', value: 0, initial: true},
        {name: 'filter_category', value: 'all', initial: true},
        {name: 'filter_subcategories', value: 0, initial: true},
        {name: 'filter_lot_type', value: 0, initial: true},
        {name: 'prefix_id', value: null, initial: true},
    ];
}

export function resetFilter() {
    return (dispatch) => {
        const filters = getDefaultFilters();
        localStorage.removeItem('selectUserFilter');
        dispatch(setFilters(filters));
    }
}

export function removeFilter(name) {
    return (dispatch) => {
        const defaultFilters = getDefaultFilters()
        const data = find(defaultFilters, {name})
        dispatch(installFilters(data))
    }
}

