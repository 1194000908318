import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setParamsTradingDurationModal} from "../../../redux/modals/modal-trading-duration/actionTradingDuration";
import WithSuspense from "../../repeating-components/wrapper-components/with-suspense";

import {onSubmitForm} from "../../../redux/modals/modal-inline-mod-check/actionModal";
const ModalBlackBacground = React.lazy(() => import('../../repeating-components/wrapper-components/modal-black-background'));

const ModalTradingDuration = () => {

    const dispatch = useDispatch();

    const {phrases: {ak_au_ph_change_duration_auction, ak_au_ph_select_number_of_days, ak_au_ph_choose_number_days,
        save, ak_au_ph_duration_auction_calculated}, xenOptions} = useSelector(state => {
        return state.lots
    })

    const {handler_type} = useSelector((state) => {
        return state.modal
    });

    const {modalShow, amountDay, sendData} = useSelector(state => {
        return state.modalTradingDuration
    });

    useEffect(() => {
        if (handler_type === 18) {
            handleShow();
        }
    }, [handler_type]);

    const setParams = (data) => {
        dispatch(setParamsTradingDurationModal(data));
    }

    const handleShow = () => {
        setParams({modalShow: !modalShow});
    }

    if (handler_type !== 18) {
        return null
    }

    const onChangeDay = (e) => {
        const val = e.target.value;
        setParams({amountDay: parseInt(val)});
    }

    const minMaxDay = xenOptions.options_day_rang.split('-');

    const getMinMaxDay = () => {

        const minDay = parseInt(minMaxDay[0]);
        const maxDay = parseInt(minMaxDay[1]);

        let options = [];

        for (let i = minDay; i <= maxDay; i++) {
            let option = <option key={i+1} value={i}>{i}</option>;
            options = [...options, option];
        }

        return options
    }

    const sendForm = () => {
        if (amountDay !== null) {
            setParams({sendData: !sendData});
            dispatch(onSubmitForm());
        }
    }

    const modalContent = () => {
        return (
            <div className='row justify-content-center'>
                <div className="col-md-6">
                    <select defaultValue={0} className="custom-select my-1" name="day"
                            onChange={(e)=> onChangeDay(e)}>
                        <option disabled={true} value={0} key={minMaxDay[0]}>{ak_au_ph_choose_number_days}</option>
                        {getMinMaxDay()}
                    </select>
                    <small className="form-text">
                        {ak_au_ph_select_number_of_days
                            .replace(/\{from\}/, minMaxDay[0])
                            .replace(/\{to\}/, minMaxDay[1])}
                    </small>
                    <small className="form-text">
                        {ak_au_ph_duration_auction_calculated}
                    </small>
                </div>
            </div>
        )
    }

    return (
        <WithSuspense>
            <ModalBlackBacground
                setShow={handleShow}
                show={modalShow}
                sendForm={sendForm}
                phraseTitle={ak_au_ph_change_duration_auction}
                phraseSubmit={save}
            >
                {modalContent()}
            </ModalBlackBacground>
        </WithSuspense>
    )


}

export default ModalTradingDuration