import {
    SET_LOT_CATEGORY_ID,
    SET_CRUD_DATA,
    SET_LOT_PARAMS,
    SET_DELIVERY_PARAMS,
    SET_IMAGES,
    SET_PAYMENT_PARAMS,
    SET_ERROR_DELIVERY,
    SET_ERROR_PAYMENT,
    SET_LOT_OPTIONS,
    SET_LOT_PREFIX,
    SET_ERROR_SUBMIT_FORM,
    SET_PROMOTION_DATA,
    SET_IMAGE_ORDER
} from "../types";

const initialState = {
    auction: {
        auction_id: null,
        auction_category_id: null,
        title: '',
        prefix_id: 0,
        message: '',
        disadvantage: '',
        expiration_date: null,
        starting_bid: null,
        buyout_price: null,
        auto_reedit_lot: 1,
        lot_type: 0,
        auction_amount: 1,
        allow_offer_price: 0,
        only_positive_balance: 0,
        auto_promotion_lot: 0,
        only_not_ignore: 0,
        only_over_one_month: 0,
        categorySettings: [],
        endDate: null,
        placement_date: 0
    },
    phrases: {},
    prefixes: {},
    attachments: [],
    imageOrder: [],

    delivery_methods: [], // [{delivery_id: 101, enabled:true, cash_on_delivery: 1, delivery_nal_pay: 0}]
    deliveryError: false,
    payment_methods: [], // [{payment_id: 1, enabled: true}]
    paymentError: false,
    errorSubmit: false,
    errorSubmitArr: [],
    categories: {},
    reedit: 0,
    create_copy: 0,

    maximumNumberDays: 0,
    minimumNumberDays: 0,
    minDayPhrase: 0,
    maxDayPhrase: 0,

    promotionData: [],
    promotionShow: false,
    selectedPromotionType: {},
    xenOptions: {},
    compressorParams: {},

    loading: true
}

export const crudLotReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ERROR_SUBMIT_FORM:
        case SET_PROMOTION_DATA:
        case SET_CRUD_DATA:
            return {...state, ...action.payload};
        case SET_DELIVERY_PARAMS:
            return {...state, delivery_methods: action.payload};
        case SET_ERROR_DELIVERY:
            return {...state, deliveryError: action.payload};
        case SET_ERROR_PAYMENT:
            return {...state, paymentError: action.payload};
        case SET_PAYMENT_PARAMS:
            return {...state, payment_methods: action.payload};
        case SET_IMAGES:
            return {...state, attachments: action.payload};
        case SET_IMAGE_ORDER:
            return {...state, imageOrder: action.payload};

        case SET_LOT_PREFIX:
        case SET_LOT_PARAMS:
        case SET_LOT_CATEGORY_ID:
        case SET_LOT_OPTIONS:
            return {...state, auction: action.payload};
        default:
            return state
    }
}