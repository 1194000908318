import {SET_PARAMS_PROMOTION_LOTS} from "../../types";
import {_defaultStateData} from "./actionPromotionLots";

const initialState = _defaultStateData()

export const promotionLotsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PARAMS_PROMOTION_LOTS:
            return {...state, ...action.payload};
        default:
            return state;
    }
}