import {SET_PARAMS_MODAL_VIEW_LOT_SALE} from "../../types";

const initialState = {
    modalShow: false,
    auction: {},
    results: [],
    phrases: {}
};

export const modalViewLotSaleReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PARAMS_MODAL_VIEW_LOT_SALE:
            return {...state, ...action.payload};
        default:
            return state
    }
}