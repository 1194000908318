import {SET_END_TIME_MODAL} from "../../types";

const initialState = {
    modalShow: false,
    newChosenTime: null,
    sendTime: false
}

export const modalSetEndTimeReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_END_TIME_MODAL :
            return {...state, ...action.payload};
        default: return state;
    }

}