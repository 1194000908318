import React, {useContext} from "react";
import CategoryBlock from "./category-block";
import {mclc} from "./modal-create-lot-content";


const CreateBlock = () =>{

    const {blockData} = useContext(mclc);
    return blockData.map((item, key) => (
        <CategoryBlock  key={key} {...item}/>
    ));
}
export default CreateBlock
