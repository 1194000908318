import {SUBSCRIPTION_PREFIX_SET_DATA} from "../../types";

const initialState = {
    modalShow: false,
    prefix_id: 0,
    action_user_id: 0,
}

export const modalSubscriptionPrefixReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUBSCRIPTION_PREFIX_SET_DATA :
            return {...state, ...action.payload};
        default:
            return state;
    }
}