import React from "react";
import './category-nav.css'

const CategoryNavContent = (props) => {
    const {showCategoryBlock, getCategories, ak_au_ph_open} = props

    return (
        <div className="row mb-1">
            <div className="col-12">
                <div className='section-main'>
                    <div className="d-flex justify-content-center">
                    <button type="button" onClick={(e) => showCategoryBlock(e)}
                            className="btn btn-outline-secondary btn-sm  d-sm-block d-lg-none d-md-none m-1">
                        {ak_au_ph_open}
                    </button>
                    </div>
                    <div id="category_block" className='d-none d-md-block'>
                        <div id='view_category_nav' className="row">
                            {getCategories}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default CategoryNavContent