import {SET_ONLINE_MODERATORS, SET_SHOW_BLOCK_ONLINE_MODERATORS,} from "../../types";

export function setOnlineModerator(onlineStaff) {
    return dispatch => {
        dispatch({type: SET_ONLINE_MODERATORS, payload: onlineStaff})
    }
}

export function showBlockOnlineModerators(){
    return (dispatch, getState) => {
        const {show} = getState().auctionModeratorsSidebar
        dispatch({type: SET_SHOW_BLOCK_ONLINE_MODERATORS, payload: !show})
    }
}