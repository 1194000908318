import React, {useMemo, useState} from "react"
import './nav-bar-sort-menu.css';
import {useSelector} from "react-redux";
import {find} from "lodash/collection";
import WithSuspense from "../repeating-components/wrapper-components/with-suspense";
import {Container, Nav, Navbar} from "react-bootstrap";
import {split} from "lodash/string";
import {filter} from "lodash/collection";
import {getQueryLinkParams} from "../../helpers/routing-helper";
import {useLocation} from "react-router-dom";

const AuctionCategoryBlock = React.lazy(() => import('./auction-category-block'));
const OwnerCategoryBlock = React.lazy(() => import('./owner-category-block'));
const SearchBlock = React.lazy(() => import('./search-block'));
const AvatarBlock = React.lazy(() => import('./avatar-block'));
const ArchiveSwitchBlock = React.lazy(() => import('./archive-switch-block'));

const NavBarSortMenu = () => {
    const location = useLocation();

    const {searchAuctions, all_categories, ak_au_ph_sort_by_category, user_lots_type, auction_category_id, routeName} = useSelector(
        state => {
            const {lots} = state

            const arr = filter(split(location.pathname, '/', 2), Boolean);
            const {user_lots_type, auction_category_id} = getQueryLinkParams();

            return {
                user_lots_type,
                auction_category_id,
                routeName: (arr.length > 0) ? arr[0] : '',
                all_categories: lots.all_categories,
                searchAuctions: lots.visitor.permissions.auctions,
                ak_au_ph_sort_by_category: lots.phrases.ak_au_ph_sort_by_category
            }
        })

    const categoryBlock = useMemo(() => {

        let categoryTitle = ak_au_ph_sort_by_category;

        if (auction_category_id !== 0) {
            const {category_title} = find(all_categories, {auction_category_id});
            categoryTitle = category_title
        }

        const types = ['archive_lots', 'auction_lots'];

        return (routeName !== 'subscriptions') ?
            (types.includes(user_lots_type))
                ? <AuctionCategoryBlock category_title={categoryTitle}/>
                : <OwnerCategoryBlock category_title={categoryTitle}/>
            : null;

    }, [auction_category_id, user_lots_type, routeName]);


    const searchBlock = (searchAuctions) ? <SearchBlock/> : null;
    const avatarBlock = (user_lots_type === 'archive_lots') ? <ArchiveSwitchBlock/> : <AvatarBlock/>;

    const wrapper = (component) => {
        return <WithSuspense>{component}</WithSuspense>
    }

    return (
        <Navbar className="rounded m-1" collapseOnSelect expand="lg" bg={"success"} variant={"dark"}>
            <Container>
                {wrapper(avatarBlock)}
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">

                    </Nav>
                    <div className="d-flex flex-wrap">
                        {wrapper(categoryBlock)}
                        {wrapper(searchBlock)}
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};


export default NavBarSortMenu;



