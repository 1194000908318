import ReibertApiService from "../../../services/reibert-api-service";
import {getIdCategories, getParams} from "../../lots/action";

import {SET_INTERESTING_LOTS_DATA} from "../../types";

const reibertApiService = new ReibertApiService();

export function getInterestingLots() {
    return async (dispatch, getState) => {

        let obj = dispatch(getParams());

        if (obj.auction_category_id) {
            obj.auction_category_id = dispatch(getIdCategories());
        }

        reibertApiService.getInterestingLots(obj).then(
            json => {
                const {interestingLots} = json
                dispatch({type: SET_INTERESTING_LOTS_DATA, payload: interestingLots})
            }
        )
    }
}