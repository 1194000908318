import {isEmpty, isNull, isUndefined} from "lodash/lang";
import {matchPath} from "react-router-dom";
import {split} from "lodash/string";
import find from 'lodash/find';
import queryString from "query-string";

export const getMatch = () => {
    const pathArr = [
        { path: '/auction/:user_id/:type?' },
        { path: "/auctions/:auction_category_id" },
        { path: "/lots/:auction_id" },
        { path: "/auction-history/:action" },
        { path: "/archive/:year/:type/:auction_category_id?/" },
    ];

    let matchParams;

    pathArr.some(({ path }) => {
        const match = matchPath(path, window.location.pathname);
        if (match && !isNull(match)) {
            matchParams = match.params;
            return true;
        }
        return false;
    });

    return matchParams;
};

export const getMatchParams = (params) =>{
    const matchParams = getMatch();

    let value = undefined;

    if (!isUndefined(matchParams)) {
        value = !isUndefined(matchParams[params]) ? matchParams[params] : undefined;

        if (matchParams.hasOwnProperty(params)) {
            const getParsXenParams = (item) => {
                const arr = split(item, '.');
                return arr[arr.length - 1];
            };

            switch (params) {
                case 'user_id':
                case 'auction_category_id':
                    return isUndefined(value) ? 0 : parseInt(getParsXenParams(value));
                case 'auction_id':
                    return isUndefined(value) ? 0 : parseInt(getParsXenParams(value));
                case 'type':
                    return isUndefined(value) ? 'for_sales' : value;
                case 'year':
                    return parseInt(value);
                default:
                    return value;
            }
        }
    }

    return params === 'auction_category_id' ? 0 : value;
};

export const getUserLotsType = (type = '') => {

    if (isEmpty(type)){
        type = getMatchParams('type');
    }

    const year = getMatchParams('year');
    const user_id = getMatchParams('user_id');

    switch (type) {
        case 'for_sales':
            return (isUndefined(user_id)) ? 'auction_lots' :
                (window.location.pathname !== "/auction-history/user-bidding") ? 'user_lots' : 'user_bidding'
        case 'sale':
            if (window.location.pathname === "/auction-history/purchased-user-lot") {
                return 'purchases'
            }
            return ((!isUndefined(user_id))) ? 'user_lots' :
                (isUndefined(year)) ? 'user_lots' : 'archive_lots'
        case 'finalized':
            return ((!isUndefined(user_id))) ? 'user_lots' :
                (isUndefined(year)) ? 'user_lots' : 'archive_lots'
        case 'sold':
            return (isUndefined(year)) ? 'user_lots' : 'archive_lots';
        case 'wait':
            return 'archive_lots'
        case 'no-sold':
            return 'archive_lots'
        case 'no_sale':
        case 'all_user_bets':
            return 'user_bidding'
        case 'vacation':
        case 'inactive_copy':
        case 'watch_lots':
        case 'nosale':
        case 'no_pay':
        case 'awaiting_shipment':
        case 'awaiting_confirmation':
        case 'awaiting_payment':
            return 'user_lots'
        case 'send':
        case 'take':
        case 'waitforpay':
            return 'purchases'
        default:
            return 'auction_lots'
    }
}

export const getDefaultOrder = (user_sort_data = undefined, arr) => {
    const { user_lots_type, type } = arr;

    if (isUndefined(user_sort_data)) {
        return 'time';
    }
    const { sort_order } = find(user_sort_data, { user_lots_type, type });
    return sort_order;
};

export  const getDirection = (user_sort_data = undefined, arr) => {
    const { user_lots_type, type } = arr;

    if (isUndefined(user_sort_data)) {
        return 'asc';
    }

    const { direction } = find(user_sort_data, { user_lots_type, type });
    return direction;
};

export const getQueryLinkParams = (user_sort_data = undefined) =>{

    const {
        page = 1,
        order,
        direction,
        prefix_id,
        disput_id,
        user_lots_type,
        type = getMatchParams('type'),
        auction_category_id = getMatchParams('auction_category_id'),
        auction_id = getMatchParams('auction_id'),
        year = getMatchParams('year'),
        month = getMatchParams('month'),
        user_id = getMatchParams('user_id'),
        search = '',
        lotType = 'all'
    } = queryString.parse(window.location.search);



    let arr = {
        year,
        type,
        order,
        direction,
        month: month,
        user_lots_type,
        page: parseInt(page),
        search: decodeURI(search),
        auction_category_id: parseInt(auction_category_id),
    };


    if (isEmpty(type)) {
        arr.type = 'for_sales'
    }

    if (isUndefined(user_lots_type)) {
        arr.user_lots_type = getUserLotsType(arr.type);

        if (arr.type === 'sold'){
            arr.type = 'sale';
        }

        if (arr.user_lots_type === 'archive_lots'){

            if (arr.type === 'wait'){
                arr.type = 'unfinished';
            }

            if (arr.type === 'no-sold'){
                arr.type = 'finalized';
            }
        }
    }

    if (isUndefined(order)) {
        arr.order = getDefaultOrder(user_sort_data, arr);
    }

    const date = new Date();
    const nowYear = date.getFullYear();

    if (arr.user_lots_type === 'archive_lots' && isUndefined(year)) {
        arr.year = nowYear;
    }

    if (arr.user_lots_type === 'archive_lots' && !isUndefined(prefix_id)) {
        arr.prefix_id = prefix_id;
    }

    if (arr.user_lots_type === 'user_lots' && type === 'for_sales') {
        arr.lotType = lotType;
    }

    if (arr.user_lots_type === 'archive_lots' && isUndefined(month)) {
        let month = 'January';
        if (arr.year === nowYear) {
            const options = { month: 'long' };
            month = date.toLocaleString('en-US', options);
        }
        arr.month = month;
    }

    if (!isUndefined(user_id)) {
        arr.user_id = parseInt(user_id);
    }

    if (isUndefined(user_lots_type)) {
        if (!isUndefined(auction_id)) {
            arr.auction_id = parseInt(auction_id);
        }
        if (!isUndefined(disput_id)) {
            arr.disput_id = parseInt(disput_id);
        }
    }

    if (isUndefined(direction)) {
        arr.direction = getDirection(user_sort_data, arr);
    }

    return arr;
}