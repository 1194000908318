import BaseApiService from "./base-api-service";

export default class LotsApiService extends BaseApiService{

    constructor() {
        super();
        this._apiBase = `${this._baseUrl}/lots`;
    }

    deleteBid = async (obj) => {
        return await this.getResource(`/del-last-bid`, obj, 'POST');
    }
    deleteAutoBid = async (obj) => {
        return await this.getResource(`/del-last-auto-bid`, obj, 'POST');
    }
}