import {SUBSCRIPTION_SET_DATA} from "../types";

const initialState = {
    subscriptionCategories: [],
    subscriptionPrefixes: [],
    subscriptionLoading : true,
};

export const subscriptionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUBSCRIPTION_SET_DATA:
            return {...state, ...action.payload};
        default:
            return state
    }
}